const kr = {
  "global": {
    "language": "한국어",
    "login": "로그인",
    "register": "가입",
    "login_out": "로그아웃",
    "login_code": "인증번호 로그인",
    "login_password": "비밀번호 로그인",
    "login_phone_holder": "핸드폰 번호를 입력해주세요",
    "login_phone_check": "핸드폰 번호 형식이 다릅니다",
    "login_code_holder": "6자리 인증번호를 입력해주세요",
    "login_code_check": "인증번호 형식이 다릅니다",
    "login_country_check": "국가를 선택해주세요",
    "login_code_get": "인증번호 발송",
    "login_code_repeat": "재발송",
    "login_code_not_received": "서비스 점검중으로 이용이 불가합니다, \n불편을 드려 죄송합니다.",
    "login_code_not_received_tip": "인증번호 수신이 안 된다면 고객센터로 문의해 주세요",
    "login_support_email": "고객센터 이메일주소",
    "login_password_holder": "비밀번호를 입력해주세요",
    "login_password_error": "8 ~20자리 대소문자, 특수문자, 숫자가 적어도 1개 이상이 포함되어야 합니다",
    "login_password_forgot": "비밀번호 설정 방법",
    "login_password_forgot_tip": "비밀번호를 잊어버리신 경우, 인증번호로 로그인해 주세요",
    "login_password_required": "비밀번호를 입력해주세요",
    "login_password_explain": "비밀번호를 잊어버리신 경우, 고객센터로 문의주세요.",
    "login_forget_password_modal_title": "비밀번호 설정 방법",
    "login_forget_password_modal_subtitle": "인증번호 로그인 후,",
    "login_forget_password_modal_item1": "서비스페이지 오른쪽 상단에 설정 버튼을 눌러주세요.",
    "login_forget_password_modal_item2": "정보수정으로 이동해 주세요.",
    "login_forget_password_modal_item3": "비밀번호 수정 버튼을 누르시면 비밀번호 설정이 가능합니다.",
    "agreement_all_agree": "모두 동의합니다",
    "agreement_age_14": "만 14세 이상입니다",
    "agreement_age_18": "만 18세 이상입니다",
    "agreement_user": "아도바로 서비스 이용약관",
    "agreement_privacy": "개인정보 수집 및 이용 동의",
    "agreement_business": "마케팅 정보 수신 동의",
    "agreement_user_title": "아도바로 서비스 이용약관",
    "agreement_user_content": "아도바로 서비스 이용약관 상세내용",
    "agreement_privacy_title": "개인정보 수집 및 이용 동의",
    "agreement_privacy_content": "개인정보 수집 및 이용 동의 상세내용",
    "agreement_business_title": "마케팅 정보 수신 동의",
    "agreement_business_content": "마케팅 정보 수신 동의 상세내용",
    "agreement_message_tip": "약관 동의가 필요합니다",
    "agreement_tip": "로그인하시려면 동의하셔야 합니다",
    "agreement_modal_title": "서비스 약관 및 개인정보 약관",
    "agreement_modal_content": "약관을 자세히 보시고 동의 부탁드립니다",
    "protocol_title_register_user": "아도바로 서비스 이용약관",
    "protocol_title_register_privacy": "개인정보 수집ㆍ이용ㆍ제공 동의서",
    "protocol_title_register_marketing": "마케팅 정보 수신 동의서",
    "protocol_title_platform_settlement": "아도바로 채널 개설 및 정산 서비스 이용약관",
    "protocol_title_platform_appoint": "위임 동의서",
    "protocol_title_home_personal": "개인정보 처리방침",
    "protocol_title_translation_service": "아도바로 ‘번역 서비스’ 이용약관",
    "protocol_checkbox_user": "아도바로 서비스 이용약관 동의",
    "protocol_checkbox_privacy": "개인정보 수집 및 이용 동의",
    "protocol_checkbox_marketing": "마케팅 정보 수신 동의 (선택)",
    "protocol_detail_page_title": "이용약관",
    "protocol_file_name_register_user": "아도바로 서비스 이용약관_v1.0",
    "protocol_file_name_platform_settlement": "아도바로 채널 개설 및 정산 서비스 이용약관_v1.0",
    "protocol_file_name_translation_service": "아도바로 번역 서비스 이용약관_v1.0",
    "protocol_file_name_register_privacy": "개인정보 수집이용 제공 동의_v1.0",
    "protocol_file_name_register_marketing": "마케팅 정보 수신 동의_v1.0",
    "protocol_file_name_home_personal": "개인정보 처리 방침_v1.0",
    "protocol_file_name_platform_appoint": "위임동의_v1.0",
    "protocol_home_footer_terms": "아도바로 이용 약관",
    "register_welcome": "가입을 환영합니다",
    "register_password_holder": "비밀번호를 입력해주세요",
    "register_password_confirm_holder": "비밀번호를 다시 입력해주세요",
    "register_password_error": "8 ~20자리 대소문자, 특수문자, 숫자가 적어도 1개 이상이 포함되어야 합니다",
    "register_password_required": "비밀번호를 입력해주세요",
    "register_password_confirm_check": "비밀번호를 확인해주세요",
    "register_password_confirm_error": "비밀번호가 일치하지 않습니다",
    "register_email_holder": "이메일을 입력해주세요",
    "register_email_check": "이메일 형식이 맞지 않습니다",
    "register_nickname_holder": "채널명을 입력해주세요",
    "register_country_holder": "국가를 선택해주세요",
    "register_classification_holder": "채널 카테고리를 선택해주세요",
    "register_to_login": "로그인하기",
    "button_add": "추가",
    "button_change": "수정",
    "button_delete": "삭제",
    "button_save": "확인",
    "button_close": "닫기",
    "button_submit": "제출",
    "button_cancel": "취소",
    "button_last_step": "이전",
    "button_next_step": "다음",
    "button_agree": "동의",
    "button_disagree": "비동의",
    "button_go": "바로가기",
    "login_button": "로그인",
    "sign_up_button": "회원가입하기",
    "register_button": "무료로 시작하기",
    "modal_cancel": "취소",
    "modal_delete": "삭제",
    "modal_create": "신규",
    "modal_save": "저장",
    "modal_confirm": "입력하신 정보가 모두 맞습니까?",
    "top_ad_close_tip": "오늘은 다시보지 않기",
    "form_holder_require": "이곳의 정보를 입력해주세요",
    "message_warning_it": "고객센터에 문의바랍니다",
    "message_success_create": "신규 추가 성공",
    "message_error_create": "신규 추가 실패",
    "message_success_update": "수정 성공",
    "message_error_update": "수정 실패",
    "message_success_delete": "삭제 성공",
    "message_error_delete": "삭제 실패",
    "message_success_upload": "업로드 성공",
    "message_error_upload": "업로드 실패",
    "message_login_success": "로그인 성공",
    "message_login_error": "로그인 실패, 아이디 혹은 비번을 확인해주세요",
    "message_phone_not_exist": "가입된 핸드폰 번호가 아닙니다",
    "message_no_token": "로그인 시간 초과, 다시 로그인 해주세요",
    "message_register_error": "가입 실패",
    "message_register_sms_fail": "유효한 인증번호가 아닙니다",
    "message_register_phone_exists": "이미 가입된 핸드폰번호입니다",
    "message_register_exists": "가입한 핸드폰 번호 혹은 이메일이 존재합니다",
    "message_register_sms_check": "가입 실패, 인증번호를 확인해주세요",
    "message_register_sign_off": "이미 탈퇴한 번호입니다, 고객센터에 문의 부탁드립니다",
    "message_sms_sended": "인증번호가 발송되었습니다",
    "message_sms_error": "인증번호 발송이 실패하였습니다",
    "message_email_sended": "이메일로 인증번호를 발송하였습니다",
    "message_email_error": "이메일 인증번호 발송이 실패했습니다",
    "not_found_title": "찾으시는 페이지가 없습니다",
    "not_found_tips": "잘못된 경로로 들어오신거 같아요",
    "empty_no_data": "데이터가 없습니다",
    "empty_on_dev": "준비중입니다",
    "upload_avatar": "프로필 사진",
    "platform_hao_kan": "하오칸 비디오",
    "platform_tencent": "텐센트 비디오",
    "platform_iqiyi": "아이치이",
    "platform_bilibili": "비리비리",
    "platform_xi_gua": "시과 비디오",
    "platform_wei_bo": "웨이보",
    "platform_youtube": "유튜브",
    "platform_dou_yin": "도우인",
    "platform_red_book": "샤오홍슈",
    "platform_kuai_shou": "콰이쇼우",
    "platform_cloud_music": "왕이윈뮤직",
    "platform_wechat_video": "웨이신스핀하오",
    "platform_mei_pai": "메이파이",
    "platform_da_yu": "따위하오",
    "platform_dian_tao": "디엔타오",
    "platform_qq_world": "큐큐월드",
    "platform_tiktok": "틱톡",
    "platform_instagram": "인스타그램",
    "platform_twitter": "트위터"
  },
  "dashboard": {
    "open_platform": "채널개설",
    "translation_service": "번역 서비스",
    "operating_service": "가이드",
    "platform_news": "뉴스레터",
    "customer_service": "고객센터",
    "download": "다운로드",
    "activity": "챌린지 신청하기"
  },
  "home": {
    "operating_platform": "당신의 콘텐츠가 더 많은 사람들에게 도달할 수 있도록",
    "operating_platform_explanation": "채널 개설부터 수익 정산까지 중국 영상 플랫폼 운영 지원 서비스, 아도바로\n중국 14억명의 시청자가 당신의 콘텐츠를 기다리고 있습니다.",
    "button_operating": "채널개설 무료신청",
    "adoba_creator_title": "중국에서 활동 중인 아도바 크리에이터",
    "adoba_creator_subtitle": "",
    "open_channel_title": "내 신분증과 내 전화번호로 만드는 내 채널!\n그리고 내 계좌로 한화정산까지!",
    "open_channel_subtitle": "",
    "open_channel_button_more": "블로그 더보기",
    "open_channel_passport_title": "내 신분증으로",
    "open_channel_passport_content": "채널 소유권 걱정 없이 본인 신분증으로 \n채널 개설이 가능합니다.",
    "open_channel_phone_title": "내 전화번호로",
    "open_channel_phone_content": "채널 보안 걱정 없이 본인 전화번호로 \n채널 관리가 가능합니다.",
    "open_channel_settlement_title": "내 계좌로",
    "open_channel_settlement_content": "수익 정산 걱정 없이 본인 계좌로 \n원화 정산이 가능합니다.",
    "story_content_cat_olin": "이미 아도바로를 경험한 크리에이터들의 이야기를 만나보세요.\n자신만의 콘텐츠를 만들어 성공을 거둔 크리에이터들의 이야기\n국내 뿐만 아니라 중국 등 다양한 플랫폼에서 인기를 얻고 있는 그들이,\n아도바로를 통해 어떻게 성공을 이루었는지 경험을 공유합니다.",
    "news_chinese_title": "중국어 공부,\n따로 할 필요가 없어요",
    "news_chinese_content": "로그인 방법부터 업로드, 채널관리 방법까지 \n누구나 따라할 수 있는 한글 가이드를 제공합니다.",
    "news_button_more": "채널 운영 가이드 보기",
    "news_hot_title": "중국 최신 트렌드,\n놓칠 걱정이 없어요",
    "news_hot_content": "플랫폼 별 이벤트, 인기 영상,\r\n중국 인기 음악 및 최신 문화 등 \r\n다양한 콘텐츠가 매일 업데이트 됩니다.",
    "news_hot_button_more": "뉴스레터 보기",
    "creator_say_title": "유튜브를 넘어서 중국에 진출한 그들의 이야기!",
    "creator_say_subtitle": "",
    "creator_title_1": "중국 플랫폼에서 정산이 가능해졌어요",
    "creator_content_1": "저는 예전부터 중국 영상 플랫폼에서 활동하고 있었지만, 늘 수익은 0원이었습니다. 영상 조회수도 1000만회가 넘었지만 외국인이었기 때문에 어떠한 수익도 낼 수 없었습니다. 중국 영상 플랫폼에서 수익을 정산 받으려면 중국 계정이 필요하기 때문입니다. 하지만 아도바와 함께하면서 아도바의 정산시스템을 통해 수익을 창출할 수 있었습니다.",
    "creator_name_1": "EatKoong吃控 박정원",
    "creator_fans_1": "중국 구독자 수 46.2만",
    "creator_title_2": "시행착오를줄이고 시간을 절약할 수 있어요",
    "creator_content_2": "지인이 중국 진출 연결을 제안해 와서 다방면으로 알아보다가 아도바와 함께하게 되었습니다. 혼자서 알아보고 진행하는 것도 이익을 많이 가져갈 수 있겠지만 여러가지 시행착오와 시간을 절약하면서 노하우를 가진 회사와 파트너십을 맺고 진행하는 것도 좋은 선택이라고 생각합니다.",
    "creator_name_2": "JJAEMI糖果罐 이종원",
    "creator_fans_2": "중국 구독자수 108만",
    "creator_title_3": "더 많은 플랫폼에 하루라도 빨리 진출하세요",
    "creator_content_3": "한국 시장은 특정 플랫폼에 이용자가 집중돼 있지만, 중국 시장에는 대규모 동영상 플랫폼이 굉장히 많이 있더라고요. 플랫폼이 다양하기 때문에 열심히 만든 콘텐츠를 여러 번 선보일 수 있다는 게 중국 시장 진출의 가장 큰 장점인 것 같아요. 매주 콘텐츠를 꾸준히 제작할 수만 있다면 고민하지 말고 하루라도 빨리 진출하라고 이야기해 주고 싶어요",
    "creator_name_3": "猫提琴CatOlin 이사라",
    "creator_fans_3": "중국 구독자수 83.7만",
    "area3_title": "크리에이터의 콘텐츠를 더 가치있게\n아도바로",
    "area3_subtitle": "",
    "area3_button": "채널개설 무료신청",
    "contents_title": "CONTENT",
    "contents_subtitle": "중국 플랫폼에 업로드된 인기있었던 영상들을 소개합니다.",
    "contents_fans_label": "중국 총 구독자수:",
    "contents_fans_unit": "만",
    "contents_platform_label": "운영하는 중국 플랫폼:",
    "footer_company_number": "사업자등록번호",
    "footer_representative": "대표",
    "footer_representative_name": "안준한",
    "footer_address": "서울오피스",
    "footer_address_content": "서울 서초구 서운로6길 26 지훈빌딩 2층, 5층",
    "footer_phone": "전화",
    "footer_email": "이메일",
    "footer_sales_number": "통신판매업 번호",
    "footer_sales_content": "제2022-서울서초-2017호",
    "footer_terms_title": "아도바로 기본약관",
    "footer_terms_member": "회원가입 약관",
    "footer_terms_use": "아도바로 이용 약관",
    "footer_terms_info_deal": "개인정보 처리방침",
    "footer_record": "Copyright© adobaro. All rights reserved"
  },
  "platform": {
    "operating_platform_title": "중국 채널 무료 개설, 지금 바로 신청하세요!",
    "platform_introduction": "플랫폼에 대해 궁금하신가요? ",
    "platform_introduction_link": "플랫폼 소개>",
    "register_guide": "채널개설과정에 대해 궁금하신가요? ",
    "register_guide_link": "채널 개설 가이드>",
    "already_open": "기존에 개설된 채널을 연동하고 싶으신가요? ",
    "already_open_link": "문의하기>",
    "status_un_apply": "개설 신청",
    "status_applying": "개설 중",
    "status_done": "개통 완료",
    "status_upcoming": "오픈예정",
    "agreement_modal_title": "아도바로에는 아래 서류들이 필요합니다",
    "agreement_modal_content": "약관을 자세히 보시고 동의를 하셔야 서비스 이용이 가능합니다",
    "agreement_all_agree": "모두 동의합니다",
    "certification_material_title": "개통 서류",
    "certification_material_missing": "제출 안한 서류가 없는지 확인하셨습니까?",
    "confirm_title": "필요 정보를 모두 입력하셔야 합니다",
    "confirm_content": "이메일 정보가 입력되지 않아, 이메일 입력을 해주세요",
    "go_back": "뒤로",
    "submit_data": "서류 제출",
    "passport_info": "신분인증 정보",
    "overseas_info": "운영채널 정보",
    "account_info": "채널 가입 정보",
    "info_confirm": "확인",
    "account_avatar": "프로필 사진",
    "account_avatar_tip": "프로필 사진을 업로드해주세요",
    "account_name": "채널 명",
    "account_name_holder": "채널 명을 입력해주세요",
    "account_nickname_error": "채널명은 중국어, 영어, 숫자만 입력 가능합니다",
    "account_introduction": "채널 소개",
    "account_introduction_error": "채널 소개는 중국어, 영어, 숫자만 입력 가능합니다.",
    "account_introduction_holder": "채널 소개를 입력해주세요",
    "account_type": "카테고리",
    "account_type_holder": "카테고리를 선택해주세요",
    "account_phone": "채널 개설할 핸드폰 번호",
    "account_phone_holder": "핸드폰 번호를 입력해주세요",
    "account_email": "채널 개설할 이메일",
    "account_email_holder": "이메일을 입력해주세요",
    "account_get_AI_help": "중국어 네이밍 Ai 이용하기",
    "platform_case": "플랫폼 선택",
    "overseas_platform": "운영중인 플랫폼",
    "overseas_platform_holder": "현재 운영중인 플랫폼을 선택해주세요",
    "overseas_link": "현재 운영 채널 링크",
    "overseas_link_holder": "현재 운영 채널 링크를 입력해주세요",
    "overseas_link_error": "운영중 플랫폼 링크가 부정확합니다, http 혹은 https 로 시작되는 확인해주세요",
    "overseas_link_method": "왜 현재 운영 채널 링크가 필요한가요?",
    "overseas_link_method_tips": "중국 플랫폼과의 적합성을 심사하기 위해 필요합니다",
    "overseas_method": "제출 서류 예시",
    "overseas_nickname": "운영중인 플랫폼 채널명",
    "overseas_nickname_holder": "현재 운영중인 플랫폼 채널명을 입력해주세요",
    "overseas_upload_proof": "파일 업로드",
    "overseas_main_page": "스튜디오 대쉬보드 스크린샷",
    "overseas_upload_main_page": "스튜디오 대쉬보드 스크린샷을 업로드해주세요",
    "overseas_main_page_tips": "채널 개인 인증을 위해 필요합니다",
    "overseas_backstage": "대쉬보드와 본인 인증 셀카",
    "overseas_upload_backstage": "대쉬보드와 본인 인증 셀카를 업로드해주세요",
    "overseas_backstage_tips": "대쉬보드가 본인임을 인증하기 위해 필요한 서류입니다",
    "personal_passport_name": "여권 영문명",
    "personal_passport_name_holder": "여권의 영문명을 입력해주세요",
    "personal_passport_number": "여권번호",
    "personal_passport_number_holder": "여권번호를 입력해주세요",
    "personal_passport_date": "여권 유효기간",
    "personal_passport_date_holder": "여권 유효기간을 입력해주세요",
    "personal_passport_date_error": "여권 유효기간이 3개월 미만입니다",
    "personal_upload_proof": "파일 업로드",
    "personal_upload_passport": "서명된 여권 스캔본\r",
    "personal_upload_passport_holder": "서명이 되어있는 여권 스캔본을 업로드해주세요",
    "personal_upload_passport_tips": "여권 스캔본은 본인 인증을 위해 필요합니다",
    "personal_method": "제출 서류 예시",
    "personal_passport_hold": "서명된 여권과 본인의 셀카\r",
    "personal_passport_hold_holder": "서명이 되어있는 여권을 들고 찍은 셀카를 업로드해주세요",
    "personal_passport_hold_tips": "본인 인증을 위해 필요합니다",
    "personal_passport_success": "여권 정보 수정을 성공하였습니다",
    "personal_passport_fail": "여권 정보 수정을 실패하였습니다",
    "personal_oversea_success": "운영 채널 정보 수정을 성공하였습니다",
    "personal_oversea_fail": "운영 채널 정보 수정을 실패하였습니다",
    "confirm_info": "확인",
    "submit_material_success": "채널개통 서류를 제출하였습니다",
    "submit_material_fail": "채널개통 서류 제출에 실패하였습니다",
    "agreement_checkbox_settlement": "아도바로 채널 개설 및 정산 서비스 이용약관 동의",
    "agreement_checkbox_appoint": "위임동의서 동의"
  },
  "translation": {
    "srt_translation": "SRT변환",
    "ai_translation": "AI 번역",
    "manual_translation": "전문 번역",
    "translation_download": "요청 내역",
    "language_select": "언어를 선택해주세요",
    "language_zh": "중국어 (간체)",
    "language_ko": "한국어",
    "language_en": "영어",
    "srt_translation_subtitle": "SRT 파일을 가지고 계신가요? 중국어 (간체) SRT 파일로 변환해드려요",
    "srt_step_title_1": "번역할 SRT 파일 언어를 선택해주세요",
    "srt_step_tip_1": "선택하신 SRT 파일 언어는 {0} 으로 번역될 예정입니다",
    "srt_step_title_2": "SRT 파일을 업로드해주세요",
    "srt_step_tip_2": "완벽한 수준의 번역이 필요하신가요? {0}에서 도와드려요.",
    "srt_translation_tip1": "무료로 제공되는 서비스입니다.",
    "srt_translation_tip2": "보통 1시간 이내에 완료됩니다. 요청 내역을 확인해 주세요.",
    "srt_translation_tip3": "번역 엔진을 기반으로 작동하므로 번역의 정확성이 미흡할 수 있습니다.",
    "file_type_error": "파일 형식은 {0} 만 지원 가능합니다",
    "file_limit_size": "파일 크기는 {0} MB보다 크면 힘들어요",
    "file_btn_status_removed": "파일 업로드",
    "file_btn_status_uploading": "업로드 중",
    "file_btn_status_done": "파일 변경하기",
    "file_btn_status_error": "재업로드 하기",
    "file_dragger_subtitle": "업로드 또는 파일을 여기로 끌어 놓으세요",
    "file_status_uploading": "업로드 완료",
    "file_status_done": "번역 완료",
    "file_status_error": "업로드 실패",
    "ai_translation_subtitle": "SRT 파일 없이 영상만 가지고 계신가요? 영상만 있어도 중국어 (간체) SRT 파일을 만들어 드려요",
    "ai_step_title_1": "영상 언어를 선택해주세요",
    "ai_step_tip_1": "선택하신 영상 언어는 {0} 으로 번역될 예정입니다",
    "ai_step_title_2": "영상 업로드",
    "ai_file_type": "업로드 형식",
    "ai_file_type_video": "직접 업로드",
    "ai_file_type_youtube": "YouTube 링크",
    "youtube_url": "YouTube 링크를 입력해주세요",
    "youtube_url_placeholder": "예시: https://www.youtube.com/adobaro ",
    "youtube_url_error": "YouTube 링크 형식이 틀립니다",
    "ai_step_tip_2": "완벽한 수준의 번역이 필요하신가요? {0}에서 도와드려요.",
    "ai_translation_tip1": "무료로 제공되는 서비스입니다.",
    "ai_translation_tip2": "보통 24시간 이내에 완료됩니다. 요청 내역을 확인해 주세요.",
    "ai_translation_tip3": "STT 변환 엔진, 번역 엔진을 기반으로 작동하므로 번역의 정확성이 미흡할 수 있습니다.",
    "manual_translation_subtitle": "보다 나은 퀄리티의 번역을 원하시나요? 전문가를 통한 번역을 요청하세요",
    "manual_translation_tip1": "유료로 제공되는 서비스입니다.\n한국어, 영어에서 중국어(간체) 번역 시 분당 5000원\n‘영상 소개’ 번역 선택시 2000원 추가\n‘번역없이 자막만’ 선택시 분당 2000원",
    "manual_translation_tip2": "보통 48시간 이내에 SRT형태로 제공됩니다. 요청 내역을 확인해 주세요.",
    "manual_translation_tip3": "업로드 전에 해당 영상을 사용/재배포 하는 것이 저작권의 소유주인지 반드시 확인해주세요.\n저작권자의 동의가 필요한 경우, 적절한 동의를 얻은 후 번역을 요청해주세요.",
    "manual_translation_tip4": "해당 영상이 저작권법을 위반하거나 명예훼손에 해당하지 않는지 반드시 확인해주세요.\n성적으로 노골적이거나 학대, 혹은 불법적인 내용을 포함하고 있지 않은지 반드시 확인해주세요.",
    "video_duration": "영상 길이",
    "video_title": "영상 제목",
    "video_title_tip": "영상 제목을 입력해주세요",
    "video_intro": "영상 소개",
    "video_intro_tip": "영상 소개를 입력해주세요",
    "video_intro_check": "영상을 소개하는 설명글도 번역하실 경우 선택해주세요",
    "subtitle_file": "자막 파일",
    "subtitle_file_tip": "이미 자막 파일을 가지고 계시면, 할인 혜택을 받으실 수 있습니다",
    "manual_type": "번역 없이\n자막만",
    "manual_type_tip": "영상 언어를 번역하지 않고, 영상 언어의 SRT 파일을 원하실 때 선택해주세요",
    "manual_language_tip": "{0} 에서 {1} 로 번역이 될 예정입니다",
    "manual_unit_count": "{0}개",
    "manual_fee": "총 요청 금액",
    "manual_fee_unit": "원",
    "protocol_tip": "서비스 이용 내용을 숙지하였고,\n이에 동의합니다",
    "protocol_check_tip": "먼저 서비스 이용에 동의해주셔야 합니다",
    "btn_manual_apply": "전문 번역 요청하기",
    "payment_process": "결제가 시작됩니다",
    "translation_download_subtitle": "요청하신 번역은 여기서 확인하실 수 있습니다",
    "file_name": "파일 명",
    "translation_category": "구분",
    "service_category": "요청 유형",
    "translation_status": "진행 상태",
    "apply_date": "요청일",
    "translation_action": "요청 자막",
    "action_download": "다운로드",
    "select_apply_date": "요청일을 선택해주세요",
    "start_date": "시작일",
    "end_date": "마지막날",
    "option_all": "전체",
    "service_cate_translate": "번역 신청",
    "service_cate_srt": "자막만 신청",
    "translation_status_waiting": "대기",
    "translation_status_translating": "진행중",
    "translation_status_success": "완료",
    "translation_status_fail": "실패",
    "total_count": "총 {0} 개의 데이터",
    "apply_success": "요청 성공",
    "apply_fail": "요청 실패",
    "btn_continue_translate": "요청 계속하기",
    "btn_retry_translate": "재요청",
    "btn_translate": "번역 요청",
    "success_tip": "현재 요청 중인 {0} 의 진행 상태를 확인 하실 수 있고, 번역이 완료된 후에 자막 다운로드가 가능합니다",
    "success_tip_list": "요청 리스트",
    "fail_tip": "요청이 실패되었습니다, {0} 에 연락주시면 해결해드리겠습니다",
    "fail_tip_customer": "고객 센터"
  },
  "activity": {
    "join_button": "챌린지 신청하기",
    "detail_button": "챌린지 알아보기",
    "open_platform_button": "채널개설 신청하기",
    "modal_success_title": "대한민국 크리에이터 중국 진출 챌린지",
    "modal_success_subtitle": "챌린지 신청이 완료되었습니다",
    "modal_failure_title": "앗! 아직 채널개설 신청이 되어있지 않아요",
    "modal_failure_subtitle": "채널개설 신청이 완료되어야 챌린지에 참여하실 수 있습니다",
    "modal_already_subtitle": "챌린지에 이미 신청하셨습니다"
  },
  "user": {
    "open_progress": "채널 개설 현황",
    "operation_data": "운영 데이터",
    "payment_history": "결제 히스토리",
    "token": "Token",
    "platform_information": "인증 정보",
    "earnings_situation": "수익 정보",
    "modify_info": "정보 수정",
    "sider_close": "메뉴접기",
    "progress_date": "개설 신청일",
    "progress_external": "적합성",
    "progress_material": "서류",
    "progress_certification": "인증",
    "progress_complete": "개설",
    "progress_ongoing": "진행중",
    "progress_completed": "완료",
    "progress_not_started": "준비중",
    "progress_not_pass": "불통과",
    "progress_contact_customer": "고객센터에 문의하여 처리하셔야 합니다",
    "progress_to_open": "개통",
    "modify_info_profile": "개인정보",
    "modify_info_phone": "핸드폰 번호",
    "modify_info_email": "이메일",
    "modify_info_password": "비밀번호",
    "modify_info_change_button": "수정",
    "modify_info_verify_button": "인증",
    "modify_profile_tip": "수정이 가능합니다",
    "modify_profile_header": "개인정보 갱신",
    "modify_profile_avatar": "프로필",
    "modify_profile_nickname": "채널명",
    "modify_profile_nickname_holder": "채널명을 입력해주세요",
    "modify_profile_nickname_check": "채널명에 띄어쓰기가 포함되어 있습니다",
    "modify_profile_intro": "채널 소개",
    "modify_profile_intro_holder": "채널 소개를 입력해주세요",
    "modify_profile_category": "카테고리",
    "modify_profile_category_holder": "채널 카테고리를 선택해주세요",
    "modify_profile_name": "성명",
    "modify_profile_name_holder": "성명을 입력해주세요",
    "modify_profile_gender": "성별",
    "modify_profile_gender_holder": "성별을 선택해주세요",
    "modify_profile_gender_man": "남",
    "modify_profile_gender_woman": "여",
    "modify_profile_birthday": "생년월일",
    "modify_profile_birthday_holder": "생년월일을 선택해주세요",
    "modify_phone_verify_head": "신분 인증",
    "modify_phone_verify_title": "핸드폰 번호를 입력해주세요",
    "modify_phone_verify_explanation": "계정 보안을 위하여 인증 단계를 거치셔야 다음 단계로 가실 수 있습니다",
    "modify_phone_verify_customer": "가입한 핸드폰 번호가 기억이 안나신다면 고객센터에 문의주세요",
    "modify_phone_verify_fail": "인증번호가 다릅니다",
    "modify_phone_holder": "현재 핸드폰 번호를 입력해주세요",
    "modify_phone_header": "변경할 핸드폰 번호",
    "modify_phone_title": "변경할 핸드폰 번호를 입력해주세요",
    "modify_phone_code_holder": "6자리 인증번호",
    "modify_phone_code_get": "인증번호 발송",
    "modify_phone_code_repeat": "재발송",
    "modify_phone_not_received": "인증번호를 받지 못하셨나요?",
    "modify_phone_confirm": "계정 보안을 위하여 가입하신 핸드폰 번호로 인증번호가 발송됩니다",
    "modify_email_header": "변경할 이메일",
    "modify_email_title": "변경할 이메일을 입력해주세요",
    "modify_email_explanation": "계정 보안을 위하여 현재 가입하신 이메일을 입력해주세요",
    "modify_email_old_holder": "현재 이메일을 입력해주세요",
    "modify_email_old_popover": "가입하신 이메일 주소가 기억이 안나신다면 고객센터로 문의해 주세요",
    "modify_email_forgot": "가입한 이메일이 기억이 안나시나요?",
    "modify_email_new_holder": "변경할 이메일을 입력해주세요",
    "modify_email_code_holder": "4자리 이메일 인증번호",
    "modify_email_check": "이메일 형식이 다릅니다",
    "modify_email_code_get": "인증번호 발송",
    "modify_email_code_repeat": "재발송",
    "modify_email_not_received": "인증번호를 받지 못하셨나요?",
    "modify_email_update_button": "이메일 변경",
    "modify_new_email": "신규 이메일",
    "modify_password_tip": "초기 비밀번호 설정 후, 비밀번호로 로그인이 가능합니다",
    "modify_password_head": "변경할 비밀번호",
    "modify_password_title": "변경할 비밀번호를 입력해주세요",
    "modify_password_explanation": "계정 보안을 위해 현재 비밀번호를 입력해주세요",
    "modify_password_old_holder": "현재 비밀번호",
    "modify_password_old_popover": "가입하신 비밀번호 기억이 안나신다면 고객센터로 문의해 주세요",
    "modify_password_forgot": "비밀번호가 기억이 안나시나요?",
    "modify_password_new_holder": "변경할 비밀번호를 입력해주세요",
    "modify_password_confirm_holder": "변경할 비밀번호를 재입력해주세요",
    "modify_password_change_button": "비밀번호 변경",
    "modify_new_password": "신규 비밀번호",
    "modify_cancel_account_btn": "회원 탈퇴",
    "modify_cancel_btn_agree": "동의하고 탈퇴신청하기",
    "modify_cancel_modal_title": "탈퇴하기",
    "modify_cancel_caption": "아래의 안내 사항을 확인하고, 비밀번호와 탈퇴 사유를 입력한 후 동의하고 탈퇴하기 신청 버튼을 눌러 아도바로 서비스를 탈퇴를 신청할 수 있습니다",
    "modify_cancel_attention_title": "서비스 탈퇴 안내 사항",
    "modify_cancel_attention_content": "1. 아직 이용중인 서비스가 종료되지 않은 경우에는 탈퇴가 진행되지 않습니다.\n2. 회원 탈퇴 후 탈퇴한 계정은 재사용 및 복구가 불가합니다.\n3. 유료로 구입하거나 무상으로 제공받은 이용권 등은 복구, 환불되지 않습니다.\n4. 개인 정보의 보호 및 사용에 따른 특별한 경우, 한시적으로 개인정보를 보존합니다.",
    "modify_cancel_agree_tip": "안내 사항을 모두 확인하였으며, 이에 동의합니다.",
    "modify_cancel_agree_check": "안내 사항을 모두 확인하였으며, 이에 동의합니다.",
    "message_sign_off_success": "성공적으로 탈퇴되었습니다",
    "message_sign_off_fail": "탈퇴 신청에 실패하였습니다",
    "earnings_title": "플랫폼 수익 내역",
    "earnings_empty": "현재 정산 가능한 채널이 없습니다",
    "earnings_apply_btn": "정산 신청",
    "earnings_modal_empty": "현재 정산 가능한 채널이 없습니다",
    "earnings_modal_close_btn": "닫기",
    "income_situation_title": "플랫폼 수익 현황",
    "income_overview_title": "수익 개요",
    "income_detail_title": "정산 내역",
    "income_bank_identity_info": "정산 계좌/신분 정보",
    "income_monetary_unit_label": "금액 단위",
    "income_monetary_unit_cny": "위안",
    "income_monetary_unit_krw": "원",
    "income_overview_balance": "수익 잔액",
    "income_overview_balance_tip": "미정산 수익 총 잔액",
    "income_overview_withdrawable": "정산 신청 가능 금액",
    "income_overview_withdrawable_tip": "수익 잔액 중 \n정산 신청이 가능한 금액",
    "income_overview_accumulate": "정산된 총 수익",
    "income_overview_accumulate_tip": "현재까지 정산된 총 금액",
    "income_withdrawable_apply_btn": "정산 신청하기",
    "income_withdrawable_detail_btn": "수익 상세",
    "income_detail_date": "정산 신청일자",
    "income_detail_total": "신청금액",
    "income_detail_tax": "중국 세금",
    "income_detail_service_charge": "수수료",
    "income_detail_amount": "정산 금액",
    "income_detail_status": "정산 현황",
    "income_detail_action": "상세 보기",
    "income_detail_status_ongoing": "정산 중",
    "income_detail_status_success": "정산 성공",
    "income_detail_status_failure": "정산 실패",
    "income_detail_to_account_date": "예정일 {0}",
    "income_detail_view_details": "상세 보기",
    "income_bank_identity_krw_title": "원화 계좌 정보",
    "income_bank_setting": "설정하기",
    "income_bank_change": "수정하기",
    "income_bank_not_set": "미설정",
    "income_bank_name": "은행",
    "income_bank_account_number": "계좌번호",
    "income_bank_account_name": "예금주",
    "income_bank_real": "소득신고자명",
    "income_bank_identity_number": "소득신고자 주민번호",
    "income_bank_verify_header": "신분 인증",
    "income_bank_verify_code_title": "인증번호를 입력해주세요",
    "income_bank_verify_content": "계정 보안을 위한 인증 번호가 {0} 으로 발송 되었습니다. 아래 인증번호를 입력하여 인증을 완료해주세요",
    "income_bank_verify_code_holder": "6자리 인증번호",
    "income_bank_verify_code_error": "인증번호가 다릅니다",
    "income_bank_verify_not_received": "인증번호를 받지 못하셨나요?",
    "income_bank_verify_modal_save": "인증하기",
    "income_bank_verify_success": "인증 성공",
    "income_bank_verify_failure": "인증 실패",
    "income_bank_change_modal_header": "정산 계좌/신분 정보 수정하기",
    "income_bank_name_holder": "은행을 선택해주세요",
    "income_bank_account_number_holder": "계좌번호를 입력해주세요",
    "income_bank_account_name_holder": "예금주를 입력해주세요",
    "income_bank_real_holder": "소득신고자명을 입력해주세요",
    "income_bank_identity_number_holder": "소득신고자 주민번호를 입력해주세요",
    "income_bank_update_fail": "은행 정보 업데이트에 실패하였습니다!",
    "income_confirm_header": "저장",
    "income_detail_modal_period": "정산 기간",
    "income_detail_modal_detail": "상세 보기",
    "income_detail_modal_amount": "플랫폼 수익",
    "income_detail_modal_tax1": "중국 세금1",
    "income_detail_modal_tax2": "중국 세금2",
    "income_detail_modal_tax3": "중국 세금3",
    "income_detail_modal_service_charge": "수수료",
    "income_detail_modal_after_tax": "수익 정산 금액",
    "income_detail_modal_bank_info": "정산 계좌정보",
    "income_detail_modal_bank_btn": "입력하러 가기",
    "income_detail_modal_to_bank": "정산 계좌 정보를 입력하셔야 정산이 가능합니다.",
    "income_detail_modal_tips_time": "· 정산신청은 매월 10일~20일에 신청가능하며, 신청 후 30일 이내 등록하신 은행계좌로 입금됩니다.",
    "income_detail_modal_tips_level": "· 정산 서비스 수수료율은 회원등급에 따라 차등 적용됩니다. (기본 수수료율은 30%입니다.)\n",
    "income_detail_modal_tips_rate": "· 수익정산 시 원화 금액은 실제 적용 환율에 따라 달라질 수 있습니다.",
    "income_detail_modal_tips_bank": "· 계좌정보가 부정확할 경우, 정산 신청이 취소될 수 있습니다.",
    "income_detail_modal_tips_tax": "· 사업소득 신고 의무에 따라 사업소득세 3.3%가 별도 공제됩니다.",
    "income_detail_modal_apply_btn": "위 내용을 모두 확인하였으며, 정산을 신청합니다",
    "income_detail_drawer_money_rate": "적용 환율",
    "income_detail_drawer_money_krw": "한화 정산 금액",
    "income_detail_drawer_tips_krw": "· 사업소득 신고 의무에 따라 사업소득세 3.3%가 별도 공제됩니다.",
    "income_modal_success_title": "정산 신청이 완료되었습니다",
    "income_modal_failure_title": "신청에 오류가 생겼습니다, 잠시 후 재신청해주세요",
    "income_modal_close_remind": "{0}초 후에 자동으로 닫힙니다",
    "income_platform_situation_title": "플랫폼 수익 개요",
    "income_platform_total": "월별 수익 (위안)",
    "income_platform_all_platform": "모든 플랫폼",
    "income_platform_detail": "플랫폼 수익 상세",
    "income_platform_table_platform_name": "플랫폼",
    "income_platform_table_platform_income": "플랫폼 수익 (위안)",
    "income_platform_table_ad_income": "광고 수익 (위안)",
    "income_platform_table_daily_income": "일별 수익",
    "income_platform_drawer_title": "{0} {1}월 수익 상세",
    "income_platform_drawer_tips_wait": "플랫폼 별로 데이터 수집 시간이 다르므로 2-3일 정도의 수집 기간이 차이가 납니다",
    "income_platform_drawer_tips_unit": "수익 금액은 위안화 기준입니다",
    "income_platform_drawer_tips_account": "일별 수익은 MCN 계정에 정산된 날을 기준으로 합니다",
    "income_platform_drawer_ad_income": "광고 수익",
    "income_platform_drawer_ad_all": "광고 총 수익",
    "income_platform_drawer_platform_income": "플랫폼 수익",
    "income_platform_drawer_platform_all": "플랫폼 총 수익",
    "income_platform_drawer_collecting": "데이터 수집중...",
    "income_platform_drawer_show_more": "더보기",
    "income_platform_drawer_show_close": "접기"
  }
};export default kr