import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import commonServices from '@/services/commonServices';
import { CountryType, PlatformType, CategoryType, CreatorType } from './types';

export default class CommonStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'CommonStore',
      properties: [],
      storage: window.sessionStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  phoneCodeList = [
    // { zh: '美国', code: 1 },
    { zh: '韩国', code: 82 },
    { zh: '中国', code: 86 },
  ];

  countryList: CountryType[] = [];
  async getCountries() {
    const res = await commonServices.getCountries();
    this.countryList = res.list;
    return Promise.resolve(res.list);
  }

  getCountryName(code?: string, language: 'zh' | 'en' | 'ko' = 'ko') {
    const country = this.countryList.find((c) => c.code === code);
    return country ? country[language] : '未知';
  }

  getCountryCode(name?: string, language: 'zh' | 'en' | 'ko' = 'ko') {
    const country = this.countryList.find((c) => c[language] === name);
    return country ? country.code : '';
  }

  categoryList: CategoryType[] = [];
  async getCategories() {
    const res = await commonServices.getCategories();
    this.setCategoryList(res.list);
    return Promise.resolve(res.list);
  }

  setCategoryList(list: CategoryType[]) {
    this.categoryList = list;
  }

  getDisplayCateList(language: 'zh' | 'en' | 'ko' = 'ko') {
    const array = this.categoryList.reduce((arr: CategoryType[], item) => {
      if (item.parentId === 0) {
        arr.push({
          ...item,
          label: item[`${language}`],
          value: item.id,
        });
      } else if (item.id > 99999 && item.id < 999999) {
        arr.forEach((a) => {
          if (item.parentId === a?.id) {
            let newItem = {
              ...item,
              label: item[`${language}`],
              value: item.id,
            };
            if (a?.children) {
              a.children.push(newItem);
            } else {
              a.children = [newItem];
            }
          }
        });
      } else {
        arr.forEach((a) => {
          a.children?.forEach((b) => {
            if (item.parentId === b?.id) {
              let newItem = {
                ...item,
                label: item[`${language}`],
                value: item.id,
              };
              if (b?.children) {
                b.children.push(newItem);
              } else {
                b.children = [newItem];
              }
            }
          });
        });
      }
      return arr;
    }, []);
    return array;
  }

  displayCateName(id?: number, language: 'zh' | 'en' | 'ko' = 'ko') {
    if (!id) {
      return '-';
    }
    let cate = '';
    let cateParentId = id;
    do {
      // eslint-disable-next-line no-loop-func
      const cateItem = this.categoryList?.find((item) => item.id === cateParentId);
      if (cateItem) {
        cate = cateItem[`${language}`] + `${cate ? `/${cate}` : cate}`;
        cateParentId = cateItem.parentId;
      } else {
        cateParentId = 0;
      }
    } while (cateParentId !== 0);
    return cate || '-';
  }

  defaultPlatformList: PlatformType[] = [
    { id: 1, name: 'platform_hao_kan', icon: require('@/assets/platform_icons/haokan.png') },
    { id: 2, name: 'platform_tencent', icon: require('@/assets/platform_icons/tencent.png') },
    { id: 3, name: 'platform_iqiyi', icon: require('@/assets/platform_icons/iqiyi.png') },
    { id: 4, name: 'platform_bilibili', icon: require('@/assets/platform_icons/bili.png') },
    { id: 5, name: 'platform_xi_gua', icon: require('@/assets/platform_icons/xigua.png') },
    { id: 6, name: 'platform_wei_bo', icon: require('@/assets/platform_icons/weibo.jpg') },
    { id: 7, name: 'platform_youtube', icon: require('@/assets/platform_icons/youtube.png') },
    { id: 8, name: 'platform_dou_yin', icon: require('@/assets/platform_icons/douyin.png') },
    { id: 9, name: 'platform_red_book', icon: require('@/assets/platform_icons/red_book.jpg') },
    { id: 10, name: 'platform_kuai_shou', icon: require('@/assets/platform_icons/kwai.jpg') },
    {
      id: 11,
      name: 'platform_cloud_music',
      icon: require('@/assets/platform_icons/net_music.png'),
    },
    { id: 12, name: 'platform_wechat_video', icon: require('@/assets/platform_icons/shipin.jpg') },
    { id: 13, name: 'platform_mei_pai', icon: require('@/assets/platform_icons/meipai.jpg') },
    { id: 14, name: 'platform_da_yu', icon: require('@/assets/platform_icons/dayu.jpg') },
    { id: 15, name: 'platform_dian_tao', icon: require('@/assets/platform_icons/diantao.png') },
    { id: 16, name: 'platform_qq_world', icon: require('@/assets/platform_icons/qqworld.jpg') },
    { id: 99, name: 'platform_youtube', icon: require('@/assets/platform_icons/youtube.png') },
    { id: 100, name: 'platform_tiktok', icon: require('@/assets/platform_icons/tiktok.png') },
    { id: 101, name: 'platform_instagram', icon: require('@/assets/platform_icons/instagram.png') },
    { id: 102, name: 'platform_twitter', icon: require('@/assets/platform_icons/twitter.png') },
  ];

  platformList: PlatformType[] = [];
  foreignPlatformsList: PlatformType[] = [];
  async getPlatforms() {
    const res = await commonServices.getPlatforms();
    const platforms = res.list.map((plat) => {
      const platform = this.defaultPlatformList.find((item) => item.id === plat.id);
      return {
        ...plat,
        id: plat.id,
        name: platform?.name || plat.name,
        icon: platform?.icon || require('@/assets/platform_icons/empty.png'),
        isForeign: plat.isForeign || false,
      };
    });

    this.platformList = (platforms || []).filter((p) => p.isForeign === false);
    this.foreignPlatformsList = (platforms || []).filter((p) => p.isForeign);
    return Promise.resolve(res.list);
  }

  getPlatformName(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.name || id;
  }

  getPlatformIcon(id?: number | string) {
    const platform = this.defaultPlatformList.find((p) => p.id === Number(id));
    return platform?.icon || require('@/assets/platform_icons/empty.png');
  }

  getPlatformEn(id?: number | string) {
    const platform = this.foreignPlatformsList.find((p) => p.id === Number(id));
    return platform?.en || '';
  }

  async getOssPolicy() {
    const res = await commonServices.getOssPolicy();
    return Promise.resolve(res);
  }

  creatorBlackList = [
    { adobaId: 4, nickname: '你好瑞现' },
    { adobaId: 6, nickname: '露营兄弟' },
    { adobaId: 11, nickname: '慧珠和猫' },
    { adobaId: 21, nickname: '小腰姐姐' },
    { adobaId: 19, nickname: '可可蒙COCOMONG' },
    { adobaId: 64, nickname: '可可蒙COCOMONG' },
    { adobaId: 29, nickname: '一分钟Fitness' },
    { adobaId: 30, nickname: '健身欧尼HomeTraining' },
    { adobaId: 31, nickname: '韩流明星Fitness' },
    { adobaId: 33, nickname: '我的外星人世界' },
    { adobaId: 34, nickname: '帅葱哥们' },
    { adobaId: 35, nickname: '吃不够的MBRO' },
    { adobaId: 37, nickname: '灵魂画手几厘米' },
    { adobaId: 38, nickname: '顺伊妈妈SUNIMOM' },
    { adobaId: 41, nickname: '张鼻猪' },
    { adobaId: 42, nickname: 'planD_' },
    { adobaId: 43, nickname: '花子阿姨家' },
    { adobaId: 44, nickname: '伽倻琴AYA' },
    { adobaId: 45, nickname: '甜甜的SUGARFUL' },
    { adobaId: 47, nickname: '韩国歌手美气MIGI' },
    { adobaId: 49, nickname: '派派玩具' },
    { adobaId: 50, nickname: '外国人in韩国' },
    { adobaId: 51, nickname: 'NCJ美甲' },
    { adobaId: 52, nickname: 'Relive手工DIY' },
    { adobaId: 53, nickname: '阿允宝宝Ayunstory' },
    { adobaId: 54, nickname: '摩西带你看韩国' },
    { adobaId: 55, nickname: 'AmingHumming' },
    { adobaId: 56, nickname: '露营的男人reme' },
    { adobaId: 57, nickname: '克灵编程大冒险' },
    { adobaId: 59, nickname: '牛奶爆炸糖' },
    { adobaId: 60, nickname: '渔场霸王流浪喵' },
    { adobaId: 61, nickname: '廷布尔' },
    { adobaId: 62, nickname: '给食王' },
    { adobaId: 63, nickname: '迷你刘' },
    { adobaId: 65, nickname: '惬意午后CozyDay' },
    { adobaId: 66, nickname: 'Way__A' },
    { adobaId: 72, nickname: '阿卡贝拉乐队EXIT' },
    { adobaId: 97, nickname: 'LovinTV' },
    { adobaId: 127, nickname: '首尔双门客栈' },
    { adobaId: 245, nickname: '吾皇率率' },
    { adobaId: 272, nickname: 'Ssuleim的漫画宇宙' },
    { adobaId: 273, nickname: 'EllenWie提琴' },
    { adobaId: 280, nickname: '魔女厨师' },
    { adobaId: 281, nickname: 'ENA老师' },
    { adobaId: 292, nickname: '爱茉莉小姐姐ROSA' },
  ];
  async getCreators() {
    const res = await commonServices.getCreators();
    let arr: CreatorType[] = [];
    (res.list || []).forEach((creator) => {
      const creatorIndex = this.creatorBlackList.findIndex((c) => c.adobaId === creator.adobaId);
      if (creatorIndex === -1) {
        arr.push(creator);
      }
    });
    return Promise.resolve(arr);
  }

  topAdVisible: boolean = false; // 顶部广告的栏展示状态
  setTopAdVisible(val: boolean) {
    this.topAdVisible = val;
  }
}
