import { makeAutoObservable, action } from 'mobx';
import { makePersistable, isHydrated, isPersisting } from 'mobx-persist-store';
import CommonStore from '../CommonStore';
import { tf } from '@/i18n/config';
import ChannelService from '@/utils/ChannelService';
import userServices from '@/services/userServices';
import StringUtils from '@/utils/StringUtils';
import {
  UserType,
  LoginType,
  RegisterType,
  ResetPasswordType,
  ResetPhoneType,
  ResetEmailType,
  UserPlatformType,
  OperatingPlatformType,
  PlatformPassportType,
  UserMixProfileType,
} from './types';

export default class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true }); // this绑定当前Store
    makePersistable(this, {
      name: 'UserStore',
      properties: ['token', 'isLogin', 'avatar', 'uid', 'phone', 'email', 'nickname'],
      storage: window.sessionStorage,
    }).then(
      action((persistStore) => {
        // console.log('persistStore', persistStore.isHydrated);
      })
    );
  }

  get isHydrated() {
    return isHydrated(this);
  }

  get isPersisting() {
    return isPersisting(this);
  }

  isLogin: boolean = false;
  setIsLogin(status: boolean) {
    this.isLogin = status;
  }

  token: string = ''; // token
  setToken(val?: string) {
    if (val) {
      this.token = `Bearer ${val}`;
    }
  }

  // 登录
  async login(params: LoginType) {
    const res = await userServices.login(params);
    this.setToken(res.token?.accessToken);
    this.setIsLogin(true);
    return Promise.resolve(res);
  }

  // 注册
  async register(params: RegisterType) {
    const res = await userServices.register(params);
    this.setToken(res.token?.accessToken);
    this.setIsLogin(true);
    return Promise.resolve(res);
  }

  // 获取user info
  async getUser() {
    const res = await userServices.getUser();
    this.saveUser(res.user);
    return Promise.resolve(res);
  }

  avatar: string = '';
  categoryId: number = 0;
  countryCode: string = '';
  deviceId: string = '';
  email: string = '';
  intro: string = '';
  isNotice: boolean = false;
  loginAddress: string = '';
  loginAgent: string = '';
  loginIp: string = '';
  loginTime: string = '';
  nickname: string = '';
  phone: string = '';
  role: number = 0;
  uid: number = 0;
  realName: string = '';
  gender: number = 0;
  birthday: string = '';
  saveUser(user: UserType) {
    this.avatar = user.avatar || '';
    this.categoryId = user.categoryId ? Number(user.categoryId) : 0;
    this.countryCode = user.countryCode || '';
    this.deviceId = user.deviceId || '';
    this.email = user.email || '';
    this.intro = user.intro || '';
    this.isNotice = user.isNotice || false;
    this.loginAddress = user.loginAddress || '';
    this.loginAgent = user.loginAgent || '';
    this.loginIp = user.loginIp || '';
    this.loginTime = user.loginTime || '';
    this.nickname = user.nickname || '';
    this.phone = user.phone || '';
    this.role = user.role || 0;
    this.uid = user.uid || 0;
    this.realName = user.realName || '';
    this.gender = user.gender || 0;
    this.birthday = user.birthday || '';

    this.updateChannelTalkUserInfo(user);
  }

  // 更新ChannelTalk用户信息
  updateChannelTalkUserInfo(user: UserType) {
    ChannelService.updateUser(
      {
        profile: {
          avatarUrl: user?.avatar || '',
          name: user?.nickname || '',
          mobileNumber: StringUtils.handleChannelTalkPhone(user?.phone),
          email: user?.email || '',
          realName: user?.realName || '',
          intro: user?.intro || '',
          isAdReceive: user?.isNotice || '',
        },
        profileOnce: {
          avatarUrl: user?.avatar || '',
          name: user?.nickname || '',
          mobileNumber: StringUtils.handleChannelTalkPhone(user?.phone),
          email: user?.email || '',
          realName: user?.realName || '',
          intro: user?.intro || '',
          isAdReceive: user?.isNotice || '',
        },
      },
      (err: any, cUser: any) => {
        if (err) {
          console.log('update channel talk user error ', err);
        } else {
          // console.log('update channel talk user success ', cUser);
        }
      }
    );
  }

  async sendSmsCode(phone: string) {
    const res = await userServices.sendCode(phone);
    return Promise.resolve(res);
  }

  async verifySmsCode(phone: string, code: string) {
    const res = await userServices.verifyCode(phone, code);
    return Promise.resolve(res);
  }

  // 检测手机号/邮箱是否已存在
  async checkExist(phone: string = '', email: string = '') {
    const res = await userServices.checkExist(
      phone !== '' ? phone : undefined,
      email !== '' ? email : undefined
    );
    return Promise.resolve(res);
  }

  // 更新个人资料
  async updateProfile(params: UserType) {
    const res = await userServices.updateMixProfile({ user: params });
    return Promise.resolve(res);
  }

  // 忘记/重置密码 传 phone code password
  // 修改密码 传 phone password oldPassword
  async resetPassword(params: ResetPasswordType) {
    const res = await userServices.resetPassword(params);
    return Promise.resolve(res);
  }

  // 修改手机号
  async resetPhone(params: ResetPhoneType) {
    const res = await userServices.resetPhone(params);
    return Promise.resolve(res);
  }

  // 发送邮箱验证码
  async sendEmailCode(email: string) {
    const res = await userServices.sendEmailCode(email);
    return Promise.resolve(res);
  }

  // 修改邮箱
  async resetEmail(params: ResetEmailType) {
    const res = await userServices.resetEmail(params);
    return Promise.resolve(res);
  }

  // 获取用户运营中的平台列表
  userPlatforms: UserPlatformType[] = [];
  async getUserPlatforms() {
    const res = await userServices.getUserPlatforms();
    this.userPlatforms = res.list;
    return Promise.resolve(res.list);
  }

  // 获取开通平台记录
  platformList: OperatingPlatformType[] = [];
  async getOperatingPlatforms(common?: CommonStore) {
    const res = await userServices.getOperatingPlatforms();
    this.platformList = res.list;
    if (common && common.platformList.length > 0) {
      this.updateChannelTalkUserPlatforms(common, res.list);
    }
    return Promise.resolve(res.list);
  }

  updateChannelTalkUserPlatforms(common: CommonStore, list: OperatingPlatformType[]) {
    let platform_not_open: string[] = [];
    let platform_on_going: string[] = [];
    let platform_opened: string[] = [];
    common.platformList.forEach((platform) => {
      const platformName = tf(`global.${common?.getPlatformName(platform.id)}`);
      const plat = list.find((p) => p.platformId === platform.id);
      if (plat) {
        if (plat.progress === 4 && plat.status === 2) {
          platform_opened.push(platformName);
        } else {
          platform_on_going.push(platformName);
        }
      } else {
        platform_not_open.push(platformName);
      }
    });
    ChannelService.updateUser(
      {
        profile: {
          platform_not_open,
          platform_on_going,
          platform_opened,
        },
        profileOnce: {
          platform_not_open,
          platform_on_going,
          platform_opened,
        },
      },
      (err: any, cUser: any) => {
        if (err) {
          console.log('update channel talk user error ', err);
        } else {
          // console.log('update channel talk user success ', cUser);
        }
      }
    );
  }

  // 开通新平台
  async operatingPlatforms(platformId: number) {
    const res = await userServices.operatingPlatforms(platformId);
    return Promise.resolve(res);
  }

  // 查看用户的认证资料
  passport: PlatformPassportType = {
    no: '',
    name: '',
    expiryDate: '',
    copyUrl: '',
    copyMoreUrl: '',
  };
  async getIdentityInfo() {
    const res = await userServices.getIdentityInfo();
    if (res.passport?.no) {
      this.passport = res.passport;
    }
    return Promise.resolve(res);
  }

  // 更新用户资料 (个人 / 认证(海外平台、护照) 资料)
  async updateMixProfile(params: UserMixProfileType) {
    const res = await userServices.updateMixProfile(params);
    return Promise.resolve(res);
  }

  // 注销账户
  async signOff(code: string) {
    const res = await userServices.signOff(code);
    return Promise.resolve(res);
  }

  // 首页达人头像
  async getAllUser() {
    const res = await userServices.getAllUser();
    return Promise.resolve(res);
  }

  // 参加活动
  async joinActivity(eventId: string) {
    const res = await userServices.joinActivity(eventId);
    return Promise.resolve(res);
  }
}
