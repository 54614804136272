const en = {
  global: {
    language: 'English',
    login: 'Login',
    register: '注册',
    login_out: '退出登录',
    login_code: '验证码登录',
    login_password: 'Password',
    login_phone_holder: '请输入手机号',
    login_phone_check: '手机号格式错误！',
    login_code_holder: '6位数短信验证码',
    login_code_check: '验证码格式错误！',
    login_country_check: '请选择国家！',
    login_code_get: '获取验证码',
    login_code_repeat: '重新发送',
    login_code_not_received: '没收到验证码？',
    login_code_not_received_tip: '如果您未收到验证码,请联系客服处理',
    login_support_email: '客服邮箱',
    login_password_holder: '请输入密码',
    login_password_error: '8-20字以上的大小写英文，数字，特殊文字（都要包括）',
    login_password_forgot: '忘记密码',
    login_password_forgot_tip: '如果您忘记密码,请尝试验证码登录',
    login_password_required: '请输入密码！',
    agreement_all_agree: '全部同意',
    agreement_age_14: '本人满14岁以上',
    agreement_age_18: '本人满18岁以上',
    agreement_user: '用户协议',
    agreement_privacy: '收集及使用个人资料',
    agreement_business: '接收营销信息',
    agreement_user_title: '用户协议',
    agreement_user_content: '用户协议内容',
    agreement_privacy_title: '收集及使用个人资料',
    agreement_privacy_content: '收集及使用个人资料协议详细内容',
    agreement_business_title: '接收营销信息',
    agreement_business_content: '接收营销信息详细内容',
    agreement_message_tip: '需要先同意协议',
    agreement_tip: '登录即表明你同意我们的',
    agreement_modal_title: '服务协议及隐私保护',
    agreement_modal_content: '为了更好的保护你的合法权益，请阅读并同意',
    register_welcome: '欢迎注册',
    register_password_holder: '请输入密码',
    register_password_confirm_holder: '请再次确认密码',
    register_password_error: '8-20字以上的大小写英文，数字，特殊文字（都要包括）',
    register_password_required: '请输入密码！',
    register_password_confirm_check: '请确认密码！',
    register_password_confirm_error: '两次输入密码不相同！',
    register_email_holder: '请输入邮箱',
    register_email_check: '邮箱格式错误！',
    register_nickname_holder: '请输入昵称',
    register_country_holder: '请选择国家',
    register_classification_holder: '请选择平台账号分类',
    register_to_login: '登录',
    button_add: '添加',
    button_change: '修改',
    button_delete: '删除',
    button_save: '确认',
    button_close: '关闭',
    button_submit: '提交',
    button_cancel: '取消',
    button_last_step: '上一步',
    button_next_step: '下一步',
    button_agree: '同意',
    button_disagree: '不同意',
    login_button: '登录',
    sign_up_button: '注册',
    register_button: '免费注册',
    modal_cancel: '取消',
    modal_delete: '删除',
    modal_create: '创建',
    modal_save: '保存',
    modal_confirm: '是否确定?',
    top_ad_close_tip: '今天不再显示',
    form_holder_require: '请输入此项!',
    message_warning_it: '请联系IT部门！',
    message_success_create: '创建成功！',
    message_error_create: '创建失败！',
    message_success_update: '修改成功！',
    message_error_update: '修改失败！',
    message_success_delete: '删除成功！',
    message_error_delete: '删除失败！',
    message_success_upload: '上传成功！',
    message_error_upload: '上传失败！',
    message_login_success: '登录成功！',
    message_login_error: '登录失败，请检查你的账号或密码！',
    message_phone_not_exist: '该手机号未注册!',
    message_no_token: '登录超时，请重新登录！',
    message_register_error: '注册失败！',
    message_register_sms_fail: '短信验证码不正确或者无效！',
    message_register_phone_exists: '该手机号已注册!',
    message_register_exists: '手机号或邮箱已存在!',
    message_register_sms_check: '注册失败！请检查你的短信验证码!',
    message_register_sign_off: '该账号已经注销，需要帮助请联系客服!',
    message_sms_sended: '短信验证码已发送！',
    message_sms_error: '短信验证码发送失败！',
    message_email_sended: '邮箱验证码已发送！',
    message_email_error: '邮箱验证码发送失败！',
    not_found_title: '没有您要找的页面',
    not_found_tips: '可能是路径错误导致',
    empty_no_data: '暂无数据',
    empty_on_dev: '敬请期待',
    upload_avatar: '上传头像',
    platform_hao_kan: '好看视频',
    platform_tencent: '腾讯视频',
    platform_iqiyi: '爱奇艺',
    platform_bilibili: '哔哩哔哩',
    platform_xi_gua: '西瓜视频',
    platform_wei_bo: '微博',
    platform_youtube: 'Youtube',
    platform_dou_yin: '抖音',
    platform_red_book: '小红书',
    platform_kuai_shou: '快手',
    platform_cloud_music: '网易云音乐',
    platform_wechat_video: '微信视频号',
    platform_mei_pai: '美拍',
    platform_da_yu: '大鱼号',
    platform_dian_tao: '点淘',
    platform_qq_world: 'QQ小世界',
    platform_tiktok: 'TikTok',
    platform_instagram: 'Instagram',
    platform_twitter: 'Twitter',
  },
  dashboard: {
    open_platform: '开通平台',
    translation_service: '翻译服务',
    operating_service: '运营服务',
    platform_news: '平台新闻',
    customer_service: '客服中心',
    download: '下载',
    activity: '活动',
  },
  home: {
    operating_platform: '为了能让你的内容到达更多的人手里',
    operating_platform_explanation:
      '从开通频道到收益结算，中国视频平台运营支援服务，adobaro\n中国有14亿观众在等待你的内容。',
    button_operating: '申请开通',
    adoba_creator_title: 'adoba达人',
    adoba_creator_subtitle: '',
    open_channel_title: '用身份证和手机号制作我的频道及结算账户',
    open_channel_subtitle: '',
    open_channel_button_more: '了解更多',
    open_channel_passport_title: '可用护照',
    open_channel_passport_content: '不用中国身份证就可以开通我的频道',
    open_channel_phone_title: '可用韩国手机',
    open_channel_phone_content: '可以使用韩国手机号码开通频道',
    open_channel_settlement_title: '没有中国账户可以结算',
    open_channel_settlement_content: '将频道的运营收益结算到创作者想要的账户',
    story_content_cat_olin:
      '让我们来看看已经体验过阿多巴罗的创作者的故事吧。\n创造属于自己的内容并获得成功的原创化妆师们的故事\n不仅在韩国国内，在中国等多个平台上人气的他们，\n分享通过adobaro如何取得成功的经验。',
    news_chinese_title: '不用学习中文',
    news_chinese_content:
      '根据各平台的画面构成(PC版、移动版)、登录方法、视频上传、工作室确认等，就可以毫无问题地运营，提供韩文化手册。',
    news_button_more: '了解更多',
    news_hot_title: '热点新闻',
    news_hot_content:
      '每天都更新的各中国平台的人气关键词，人气频道，各种活动，今天在中国流行的音乐，电视剧，趋势等提供影像素材。',
    news_hot_button_more: '了解更多',
    creator_say_title: '他们在YouTube之外选择中国的故事！',
    creator_say_subtitle: '',
    creator_title_1: '可以在中国平台进行清算了',
    creator_content_1:
      '要想在中国视频平台上获得收益，需要中国账号。也就是说，不是中国人的外国人不能在中国获得任何视频收益。“在与adoba合作之前，我的视频点击率超过了1000万次，但收益为0韩元。但在与adoba合作的过程中，adaba的清算系统创造了收益。',
    creator_name_1: 'EatKoong吃控 박정원',
    creator_fans_1: '中国订阅人数46.2万',
    creator_title_2: '这样就能减少执行错误，节省时间',
    creator_content_2:
      '朋友提议联系我进军中国，我在多方面了解了一下，后来和adoba一起合作了。我认为，一个人去了解并进行可能会带来很多利益，但在节约各种执行错误和时间的同时，与拥有经验的公司建立合作伙伴关系也是很好的选择。',
    creator_name_2: 'JJAEMI糖果罐 이종원',
    creator_fans_2: '中国订阅人数108万',
    creator_title_3: '请尽快进入更多的平台',
    creator_content_3:
      '韩国市场的用户集中在特定平台上，但中国市场上有很多大规模视频平台。因为平台多样，可以多次展示努力制作的内容，这是进军中国市场的最大优点。只要我们每周都能不断地制作内容我想告诉他们不要烦恼要尽快进军海外市场',
    creator_name_3: '猫提琴CatOlin 이사라',
    creator_fans_3: '中国订阅人数83.7万',
    area3_title: '开设中国频道',
    area3_subtitle: '',
    area3_button: '申请开通',
    contents_title: 'CONTENT',
    contents_subtitle: '介绍在中国平台上上传的热门视频。',
    contents_fans_label: '国内平台总粉丝：',
    contents_fans_unit: '万',
    contents_platform_label: '运营的中国平台：',
    footer_company_number: '公司号码',
    footer_representative: '代表',
    footer_representative_name: '안준한',
    footer_address: '地址',
    footer_address_content: '서울 서초구 서운로6길 26 지훈빌딩 2층, 5층',
    footer_phone: '电话',
    footer_email: '邮箱',
    footer_sales_number: '通讯销售业号码',
    footer_sales_content: '제2022-서울서초-2017호',
    footer_terms_title: 'adoba条款',
    footer_terms_member: '会员加入条款',
    footer_terms_use: '服务使用条款',
    footer_terms_info_deal: '个人信息处理方针',
    footer_record: 'Copyright© adobaro. All rights reserved',
  },
  platform: {
    operating_platform_title: '中国平台账号，免费开通！',
    platform_introduction: '想要了解平台吗？',
    platform_introduction_link: '平台介绍>',
    register_guide: '想要了解开通平台流程吗？',
    register_guide_link: '开通平台指南>',
    already_open: '您已经有开通平台吗？',
    already_open_link: '询问>',
    status_un_apply: '立即开通',
    status_applying: '开通中',
    status_done: '开通完成',
    status_upcoming: '即将开通',
    agreement_modal_title: '允许adoba road获取以下资料',
    agreement_modal_content: '为了更好地为你提供服务，请阅读并同意',
    agreement_all_agree: '全部同意',
    certification_material_title: '认证资料',
    certification_material_missing: '您还未提交认证资料,是否前往提交?',
    go_back: '返回',
    submit_data: '提交资料',
    passport_info: '护照信息',
    overseas_info: '海外平台信息',
    account_info: '运营信息',
    info_confirm: '信息确认',
    account_avatar: '头像',
    account_avatar_tip: '请上传头像',
    account_name: '账号名',
    account_name_holder: '请输入账号名',
    account_nickname_error: '只能输入中文、英文和数字',
    account_introduction: '简介',
    account_introduction_error: '只能输入中文、英文和数字',
    account_introduction_holder: '请输入平台简介',
    account_type: '分类',
    account_type_holder: '请选择账号分类',
    account_phone: '注册手机号',
    account_phone_holder: '请输入注册手机号',
    account_email: '电子邮箱地址',
    account_email_holder: '请输入电子邮箱地址',
    account_get_AI_help: '获得AI帮助',
    platform_case: '平台案例',
    overseas_platform: '海外平台',
    overseas_platform_holder: '请选择海外平台',
    overseas_link: '平台链接',
    overseas_link_holder: '请输入平台链接',
    overseas_link_error: '平台链接不正确,需以http或https开头',
    overseas_link_method: '了解如何获取平台链接',
    overseas_link_method_tips: '在您的平台主页,复制当前页面链接',
    overseas_method: '详细获取方法',
    overseas_nickname: '平台昵称',
    overseas_nickname_holder: '请输入平台昵称',
    overseas_upload_proof: '上传证明材料',
    overseas_main_page: '个人主页截图',
    overseas_upload_main_page: '请上传手机端或者电脑端个人主页截图',
    overseas_main_page_tips: '请按照以下示例图片截图',
    overseas_backstage: '平台后台与本人合照',
    overseas_upload_backstage: '请上传平台后台与本人合照',
    overseas_backstage_tips: '请按照以下示例图片拍照',
    personal_passport_name: '护照英文名',
    personal_passport_name_holder: '请输入护照英文名',
    personal_passport_number: '护照号',
    personal_passport_number_holder: '请输入护照号',
    personal_passport_date: '有效期限',
    personal_passport_date_holder: '请选择护照有效期限',
    personal_passport_date_error: '护照有效期不能少于3个月',
    personal_upload_proof: '上传证明材料',
    personal_upload_passport: '护照扫描件',
    personal_upload_passport_holder: '请上传个人护照扫描件',
    personal_upload_passport_tips: '请按照以下示例图片拍照',
    personal_method: '详细获取方法',
    personal_passport_hold: '手持护照照片',
    personal_passport_hold_holder: '请上传手持护照照片',
    personal_passport_hold_tips: '请按照以下示例图片拍照',
    personal_passport_success: '护照信息更新成功',
    personal_passport_fail: '护照信息更新失败',
    personal_oversea_success: '海外平台信息更新成功',
    personal_oversea_fail: '海外平台信息更新失败',
    confirm_info: '确认信息',
    submit_material_success: '平台资料提交成功',
    submit_material_fail: '平台资料提交失败',
    agreement_checkbox_settlement: '同意开设平台及结算服务使用条款',
    agreement_checkbox_appoint: '委任同意书',
  },
  activity: {
    join_button: '参加活动',
    detail_button: '活动详细内容',
    open_platform_button: '开通平台',
    modal_success_title: '活动名称',
    modal_success_subtitle: '参加成功',
    modal_failure_title: '您还没有开通平台',
    modal_failure_subtitle: '申请开通平台之后，才能参加活动',
    modal_already_subtitle: '您已参加活动',
  },
  user: {
    open_progress: '开通平台进度',
    operation_data: '运营数据',
    payment_history: '付款历史',
    token: 'Token',
    platform_information: '认证资料',
    earnings_situation: '收益情况',
    modify_info: '修改信息',
    sider_close: '收起侧边栏',
    progress_date: '申请日期',
    progress_external: '站外问题',
    progress_material: '资料问题',
    progress_certification: '认证问题',
    progress_complete: '完毕',
    progress_ongoing: '进行中',
    progress_completed: '已完成',
    progress_not_started: '未开始',
    progress_not_pass: '未通过',
    progress_contact_customer: '联系客服处理',
    progress_to_open: '去开通',
    modify_info_profile: '个人资料',
    modify_info_phone: '手机号码',
    modify_info_email: '邮箱',
    modify_info_password: '账户密码',
    modify_info_change_button: '更改',
    modify_info_verify_button: '验证',
    modify_profile_tip: '已设置，可更改',
    modify_profile_header: '更新个人资料',
    modify_profile_avatar: '头像',
    modify_profile_nickname: '账户名',
    modify_profile_nickname_holder: '请输入账户名',
    modify_profile_nickname_check: '账户名不可以包含空格',
    modify_profile_intro: '简介',
    modify_profile_intro_holder: '请输入简介',
    modify_profile_category: '分类',
    modify_profile_category_holder: '请选择分类',
    modify_profile_name: '姓名',
    modify_profile_name_holder: '请输入姓名',
    modify_profile_gender: '性别',
    modify_profile_gender_holder: '请选择性别',
    modify_profile_gender_man: '男',
    modify_profile_gender_woman: '女',
    modify_profile_birthday: '生日',
    modify_profile_birthday_holder: '请选择生日',
    modify_phone_verify_head: '身份验证',
    modify_phone_verify_title: '请输入现在手机号',
    modify_phone_verify_explanation: '为了你的账户安全，请验证身份。验证成功后进行下一步操作。',
    modify_phone_verify_customer: '忘记手机号请联系客服',
    modify_phone_verify_fail: '手机号验证失败！',
    modify_phone_holder: '请输入手机号',
    modify_phone_header: '更改手机号',
    modify_phone_title: '请输入新手机号',
    modify_phone_code_holder: '6位短信验证码',
    modify_phone_code_get: '获取验证码',
    modify_phone_code_repeat: '重新发送',
    modify_phone_not_received: '没收到验证码？',
    modify_email_header: '更新邮箱',
    modify_email_title: '更改邮箱',
    modify_email_explanation: '为了你的账户安全，请先输入旧邮箱进行验证。',
    modify_email_old_holder: '请输入旧邮箱',
    modify_email_old_popover: '如果您不记得您加入的电子邮件地址，请向客服中心咨询',
    modify_email_forgot: '忘记邮箱？',
    modify_email_new_holder: '请输入新邮箱获取验证码进行绑定',
    modify_email_code_holder: '4位邮箱验证码',
    modify_email_check: '邮箱格式错误！',
    modify_email_code_get: '获取验证码',
    modify_email_code_repeat: '重新发送',
    modify_email_not_received: '没收到验证码？',
    modify_email_update_button: '更新邮箱',
    modify_password_tip: '设置密码后，可通过账号密码登录',
    modify_password_head: '更新密码',
    modify_password_title: '更改密码',
    modify_password_explanation: '为了你的账户安全，请先输入旧密码进行验证。',
    modify_password_old_holder: '请输入旧密码',
    modify_password_old_popover: '如果您不记得您加入的密码，请向客服中心咨询',
    modify_password_forgot: '忘记密码？',
    modify_password_new_holder: '请输入新密码',
    modify_password_confirm_holder: '请再次输入密码',
    modify_password_change_button: '更新密码',
    modify_cancel_account_btn: '注销账号',
    modify_cancel_btn_agree: '同意申请注销',
    modify_cancel_modal_title: '注销账号',
    modify_cancel_caption: '说明文案',
    modify_cancel_attention_title: '注销账号注意事项',
    modify_cancel_attention_content:
      '1 注意事项说明\r\n2 注意事项说明\r\n3 注意事项说明\r\n4 注意事项说明',
    modify_cancel_agree_tip: '请点击同意',
    modify_cancel_agree_check: '我同意以上条款,并申请注销',
    message_sign_off_success: '您已成功注销账户',
    message_sign_off_fail: '注销账户失败',
    earnings_title: '平台收益信息',
    earnings_empty: '现在没有能提现的平台账号',
    earnings_apply_btn: '申请提现',
    earnings_modal_empty: '现在没有能提现的平台账号',
    earnings_modal_close_btn: '关闭',
    income_situation_title: '平台收益情况',
    income_overview_title: '收益概览',
    income_detail_title: '提现明细',
    income_bank_identity_info: '银行/身份信息',
    income_monetary_unit_label: '单位',
    income_monetary_unit_cny: '元',
    income_monetary_unit_krw: '韩币',
    income_overview_balance: '余额',
    income_overview_balance_tip: '未结算总收益余额',
    income_overview_withdrawable: '可提现金额',
    income_overview_withdrawable_tip: '收益余额中可以申请结算的金额',
    income_overview_accumulate: '累计提现金额',
    income_overview_accumulate_tip: '到目前为止结算的总金额',
    income_withdrawable_apply_btn: '申请提现',
    income_withdrawable_detail_btn: '查看详情',
    income_detail_date: '提现日期',
    income_detail_total: '收益合计(含税)',
    income_detail_tax: '扣税',
    income_detail_service_charge: '手续费',
    income_detail_amount: '打款金额',
    income_detail_status: '打款状态',
    income_detail_action: '操作',
    income_detail_status_ongoing: '处理中',
    income_detail_status_success: '提现成功',
    income_detail_status_failure: '提现失败',
    income_detail_to_account_date: '预计{0}到账',
    income_detail_view_details: '查看详细',
    income_bank_identity_krw_title: '韩币账户信息',
    income_bank_setting: '设置账户信息',
    income_bank_change: '变更银行信息',
    income_bank_not_set: '未设置',
    income_bank_name: '开户银行',
    income_bank_account_number: '账户',
    income_bank_account_name: '账户名',
    income_bank_real: '真实姓名',
    income_bank_identity_number: '身份证号码',
    income_bank_verify_header: '身份验证',
    income_bank_verify_code_title: '请输入验证码',
    income_bank_verify_content:
      '为了你的账户安全，请验证身份。我们已经向手机号{0}发送验证码，请在下方输入验证码，完成验证。',
    income_bank_verify_code_holder: '请输入6位数短信验证码',
    income_bank_verify_code_error: '验证码不正确！',
    income_bank_verify_not_received: '没收到验证码？',
    income_bank_verify_modal_save: '验证',
    income_bank_verify_success: '验证成功',
    income_bank_verify_failure: '验证失败',
    income_bank_change_modal_header: '修改银行/身份信息',
    income_bank_name_holder: '请选择开户银行',
    income_bank_account_number_holder: '请输入账户',
    income_bank_account_name_holder: '请输入账户名',
    income_bank_real_holder: '请输入真实姓名',
    income_bank_identity_number_holder: '请输入身份证号码',
    income_bank_update_fail: '更新银行身份信息失败!',
    income_confirm_header: '确认信息',
    income_detail_modal_period: '结算周期',
    income_detail_modal_detail: '提现明细',
    income_detail_modal_amount: '可提现金额',
    income_detail_modal_tax1: '扣税1',
    income_detail_modal_tax2: '扣税2',
    income_detail_modal_tax3: '扣税3',
    income_detail_modal_service_charge: '手续费',
    income_detail_modal_after_tax: '税后金额',
    income_detail_modal_bank_info: '银行信息',
    income_detail_modal_bank_btn: '填写银行信息',
    income_detail_modal_to_bank: '填写银行信息后，才能申请提现',
    income_detail_modal_tips_time: '· 申请后30个工作日内到账户',
    income_detail_modal_tips_level: '· 手续费按照会员等级变动',
    income_detail_modal_tips_rate: '· 按照打款汇率，打款韩币金额会变动',
    income_detail_modal_tips_bank: '· 银行信息不正确的话，申请提现会失败',
    income_detail_modal_tips_tax: '· 按照税法，需要扣所得税3.3%',
    income_detail_modal_apply_btn: '确认提交，申请提现',
    income_detail_drawer_money_rate: '打款汇率',
    income_detail_drawer_money_krw: '打款韩币',
    income_detail_drawer_tips_krw: '· 打款韩币已经扣个人所得税3.3%',
    income_modal_success_title: '提现申请成功',
    income_modal_failure_title: '系统有误，请稍后申请',
    income_modal_close_remind: '此弹窗将在{0}秒后自动关闭',
    income_platform_situation_title: '平台收益概况',
    income_platform_total: '总收入(元)',
    income_platform_all_platform: '全部平台',
    income_platform_detail: '平台收益详细',
    income_platform_table_platform_name: '平台',
    income_platform_table_platform_income: '平台收益(元)',
    income_platform_table_ad_income: '广告收益(元)',
    income_platform_table_daily_income: '每日收益',
    income_platform_drawer_title: '{0}{1}月份收入详情',
    income_platform_drawer_tips_wait: '各平台数据收集时间不同，请耐心等待',
    income_platform_drawer_tips_unit: '收益金额是人民币',
    income_platform_drawer_tips_account: '日期收益以MCN账户到账为准',
    income_platform_drawer_ad_income: '广告收益',
    income_platform_drawer_ad_all: '广告总收益',
    income_platform_drawer_platform_income: '平台收益',
    income_platform_drawer_platform_all: '平台总收益',
    income_platform_drawer_collecting: '数据收集中',
    income_platform_drawer_show_more: '展开更多',
    income_platform_drawer_show_close: '收起',
  },
};
export default en;
