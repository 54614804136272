import { tf } from '@/i18n/config';
import { CountryType } from '@/stores/CommonStore/types';
import Cookie from 'js-cookie';
import moment from 'moment';

const StringUtils = {
  /**
   * 转译正则表达式中的特殊字符
   * @params value 需要转译的目标值
   * @returns value 转译后的值
   */
  addEscape: (value: string) => {
    let arr = [
      '(',
      '[',
      '{',
      '/',
      '^',
      '$',
      '¦',
      '}',
      ']',
      ')',
      '?',
      '*',
      '+',
      '.',
      "'",
      '"',
      '\\',
    ];
    for (let i = 0; i < arr.length; i++) {
      if (value) {
        if (value.indexOf(arr[i]) > -1) {
          const reg = (str: any) => str?.replace(/[[\\\]/{}()*'"¦+?.\\\\^$|]/g, '\\$&');
          value = reg(value);
        }
      }
    }
    return value;
  },

  /**
   * 关键字变色
   * @params content 内容
   * @params keyword 关键词
   * @params tagName 标签名
   */
  warpTag: (content: any, keyword: any, tagName: any) => {
    if (content === 'No results') {
      return content;
    }
    const a = content?.toLowerCase();
    const b = keyword?.toLowerCase();

    const indexOf = a?.indexOf(b);
    const c = indexOf > -1 ? content?.substr(indexOf, keyword.length) : '';
    const val = `<${tagName} style="color:#FF6600;">${c}</${tagName}>`;
    // const regS = new RegExp(keyword, "gi");
    const regS = new RegExp(StringUtils.addEscape(keyword), 'gi'); // 转译keyword中的特殊字符
    return content?.replace(regS, val);
  },

  // 展示时间 00:00
  displayDuration: (duration: number) => {
    const minutes = parseInt((duration / 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    const seconds = parseInt((duration % 60).toString(), 10)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  },

  /**
   * 将数字转为以万为单位
   * @params value 需要转换的数字或字符串
   * @params decimal 非万为单位时需要保留几位小数,默认保留两位
   */
  handleThousand: (value: any, float: number = 1) => {
    let num = Number(value);
    if (num === 0) {
      return num + '';
    } else if (num >= 1 && num < 10000) {
      // return num.toFixed(float) + '';
      return num + '';
    } else {
      // 保留一位小数，不进行四舍五入
      value = (num / 10000).toString();
      let index = value.indexOf('.');
      if (index !== -1) {
        value = value.substring(0, float + index + 1);
      } else {
        value = value.substring(0);
      }
      return parseFloat(value).toFixed(float) + 'w';
    }
  },

  /**
   * 转为百分数并保留两位小数
   * @params value 需要处理的值
   * @return percent 保留两位小数的百分数
   */
  toPercent: (value: number | string | undefined) => {
    if (value === undefined) value = 0;
    if (value === -1) return (value = '-');
    let percent = (Number(value) * 100).toFixed(2);
    percent += '%';
    return percent;
  },

  /**
   * 打开新的页面
   * @params url 跳转的目标路径
   */
  handleOpenNewPage: (url: string) => {
    const w = window.open('about:blank');
    if (url) {
      w!.location.href = url;
    } else {
      w!.location.href = '404';
    }
  },

  /**
   * 处理手机号  传入 86-18888888888 返回 +8618888888888
   */
  handleChannelTalkPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '';
    }
    const countryCode = phoneNumber.split('-')[0];
    const phoneNum = phoneNumber.split('-')[1];
    const phone = '+' + countryCode + phoneNum;
    return phone;
  },

  /**
   * 展示手机号  传入 86-18888888888 展示 188*****888
   */
  displayPhone: (phoneNumber?: string) => {
    if (!phoneNumber) {
      return '-';
    }
    const phoneNum = phoneNumber.split('-')[1];
    const phone =
      phoneNum.slice(0, 3) +
      '******************'.slice(0, phoneNum.length - 6) +
      phoneNum.slice(phoneNum.length - 3, phoneNum.length);
    return phone;
  },

  /**
   * 展示邮箱  传入 piaodayi@adoba.net 展示 pia*****@adoba.net
   */
  displayEmail: (emailString?: string) => {
    if (!emailString) {
      return '-';
    }
    const emailStr = emailString.split('@')[0];
    const email = emailStr.slice(0, 3) + '***@' + emailString.split('@')[1];
    return email;
  },

  /**
   * 根据合同状态显示‘运营中’‘解约’‘休眠’及对应颜色
   * @params value 合同的类型：1,2,3
   * @params type 需要展示文字或者颜色
   */
  handleContractStatus: (value: number, type: 'text' | 'color') => {
    let status: string = '';
    let view: string = '#ccc';

    switch (value) {
      case 0:
        status = '运营中';
        view = '#1db100';
        break;
      case 1:
        status = '解约';
        view = '#929292';
        break;
      case 2:
        status = '未签约';
        view = '#ccc';
        break;
      case 3:
        status = '未运营';
        view = '#feae00';
        break;
      default:
        status = '-';
        break;
    }
    if (type === 'text') return status;
    if (type === 'color') return view;
  },

  /**
   * 处理日期显示格式
   * @params date 需要处理的日期
   * @return dateShow 处理后需要展示的日期格式
   */
  handleDateShow: (date?: string) => {
    let dateShow = '-';
    if (date && date !== '0001-01-01T00:00:00Z') {
      dateShow = moment(date).format('YYYY-MM-DD');
    } else {
      dateShow = '-';
    }
    return dateShow;
  },

  /**
   * 处理当前选择的语言缩写
   * @params language 当前选择的语言类型
   * @return country 根据当前语言返回的缩写
   */
  handleCountryLangView: (language: string = 'cn') => {
    let country: 'cn' | 'en' | 'kr' = 'cn';
    switch (language) {
      case 'cn':
        country = 'cn';
        break;
      case 'en':
        country = 'en';
        break;
      case 'kr':
        country = 'kr';
        break;
      default:
        country = 'cn';
        break;
    }
    return country;
  },

  /**
   * 处理选择国籍的翻译
   * @param language 当前选择的语言
   * @param item CountryType
   * @returns 根据当前选择的语言返回对应的语言
   */
  handleCountryName: (language: string = 'zh', item: CountryType) => {
    let nameLang: string = item?.zh;
    switch (language) {
      case 'zh':
        nameLang = item?.zh;
        break;
      case 'en':
        nameLang = item?.en;
        break;
      case 'ko':
        nameLang = item?.ko;
        break;
      default:
        nameLang = item?.zh;
        break;
    }
    return nameLang;
  },

  /**
   * 处理合同类型的显示
   * @param type 返回的合同类型
   * @returns 需要展示的合同类型名称
   */
  handleContractTypeShow: (type?: number) => {
    let typeName: string = '-';
    switch (type) {
      case 1:
        typeName = tf('account.contract_type_personal'); // 个人
        break;
      case 2:
        typeName = tf('account.contract_type_enterprise'); // 企业
        break;
      case 3:
        typeName = tf('account.contract_type_mcn'); // MCN
        break;
      default:
        typeName = '-';
        break;
    }
    return typeName;
  },

  /**
   * 处理站外链接
   * @param name 链接名称
   * @returns
   */
  handleOffSiteLink: (
    name:
      | 'activity' // 活动
      | 'platform_introduce' // 开通平台-平台介绍
      | 'platform_guide' // 开通平台-开通平台指南
      | 'home_learn_more' // 首页-了解更多
      | 'home_no_chinese' // 首页-不用学习中文，了解更多
      | 'home_hot_news' // 首页-热点新闻，了解更多
  ) => {
    let url = '';
    switch (name) {
      case 'activity':
        // url = 'http://2022event.adoba.net/';
        url = 'https://bit.ly/3M1VcH8';
        break;
      case 'platform_introduce':
        // url = 'https://guide.adobaro.com/pl';
        // url = 'https://guide.adobaro.com/platform/intro';
        url = 'https://guide.adobaro.com/4e98c2bb-9dcb-4d60-a511-72af7aec1f50';
        break;
      case 'platform_guide':
        // url = 'https://guide.adobaro.com/6f2350b8-d29e-4ca4-a2b8-65cd592a02c7';
        // url = 'https://guide.adobaro.com/8c4656ad-30d6-4868-8ce1-a0a9228856f2';
        url = 'https://guide.adobaro.com/0595afe0-4b2b-454b-914b-2d93ea569327';
        break;
      case 'home_learn_more':
        url = 'https://blog.naver.com/hello_adoba';
        break;
      case 'home_no_chinese':
        url = 'https://guide.adobaro.com/';
        break;
      case 'home_hot_news':
        url = 'https://newsletter.adobaro.com/';
        break;
    }
    return url;
  },

  /**
   * 获取当前项目环境
   * @returns env 'dev' | 'prod'
   */
  getEnvironment: () => {
    const currentHost: string = window.location.host;
    switch (currentHost) {
      case 'www.adobaro.com':
      case 'adobaro.com':
      case 'v1.adobaro.com':
      case '3.38.221.35': // 临时 生产环境
        return 'prod';
      default:
        return 'dev';
    }
  },

  /**
   * 清除cookies
   */
  removeCookies: () => {
    Cookie.remove('token');
    Cookie.remove('refresh_token');
    Cookie.remove('ch-veil-id', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-veil-id', { path: '', domain: 'www.adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'adobaro.com' });
    Cookie.remove('ch-session-99516', { path: '', domain: 'www.adobaro.com' });
  },
};

export default StringUtils;
