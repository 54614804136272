import { axios, axios2 } from '../utils/axios';
import {
  UserType,
  LoginType,
  RegisterType,
  ResetPasswordType,
  ResetPhoneType,
  ResetEmailType,
  UserPlatformType,
  OperatingPlatformType,
  IdentityType,
  UserMixProfileType,
} from '@/stores/UserStore/types';

export interface IGetUserResponse {
  user: UserType;
  token?: {
    accessToken: string;
    refreshToken: string;
  };
}

interface IUserPlatformsResponse {
  list: UserPlatformType[];
}

interface IOperatingPlatformsResponse {
  list: OperatingPlatformType[];
}

const userServices = {
  sendCode: (phone: string) => axios2.get('/user/v1/verify_code', { params: { phone } }),
  verifyCode: (phone: string, code: string) =>
    axios2.post<IGetUserResponse, IGetUserResponse>('/user/v1/verify_code', { phone, code }),

  checkExist: (phone?: string, email?: string) =>
    axios2.get('/user/v1/exist', { params: { phone, email } }),

  login: (params: LoginType) =>
    axios2.post<IGetUserResponse, IGetUserResponse>('/user/v1/login', params),
  register: (params: RegisterType) =>
    axios2.post<IGetUserResponse, IGetUserResponse>('/user/v1', params),

  getUser: () => axios.get<IGetUserResponse, IGetUserResponse>('/user/v1'),

  updateProfile: (params: UserType) =>
    axios.patch<IGetUserResponse, IGetUserResponse>('/user/v1/profiles', params),

  resetPassword: (params: ResetPasswordType) =>
    axios.patch<IGetUserResponse, IGetUserResponse>('/user/v1/passwd', params),

  resetPhone: (params: ResetPhoneType) =>
    axios.patch<IGetUserResponse, IGetUserResponse>('/user/v1/phone', params),

  sendEmailCode: (email: string) => axios.get('/user/v1/verify_code', { params: { email } }),

  resetEmail: (params: ResetEmailType) =>
    axios.patch<IGetUserResponse, IGetUserResponse>('/user/v1/email', params),

  // 用户运营中的平台列表
  getUserPlatforms: () =>
    axios.get<IUserPlatformsResponse, IUserPlatformsResponse>('/user/v1/operating_platforms'),
  // 开通平台记录
  getOperatingPlatforms: () =>
    axios.get<IOperatingPlatformsResponse, IOperatingPlatformsResponse>(
      '/user/v1/opening_platform_records'
    ),
  operatingPlatforms: (platformId: number) =>
    axios.put<IOperatingPlatformsResponse, IOperatingPlatformsResponse>(
      '/user/v1/opening_platform_record',
      {
        platformId,
      }
    ),

  // 查看认证资料
  getIdentityInfo: () => axios.get<IdentityType, IdentityType>('/user/v1/identity'),

  // 提交认证资料
  // commitCertificateMaterials: (params: IdentityType) =>
  //   axios.put<IdentityType, IdentityType>('/user/v1/materials_certificate', params),

  // 更新用户资料 (个人 / 认证(海外平台、护照) 资料)
  updateMixProfile: (params: UserMixProfileType) => axios.put('/user/v1/mix_info', params),

  // 注销账号
  signOff: (code: string) => axios.delete(`/user/v1/${code}`),

  // 首页达人头像
  getAllUser: () => axios.get<{ list: UserType[] }, { list: UserType[] }>('/user/v1/all'),

  // 参加活动
  joinActivity: (eventId: string) => axios.post(`/user/v1/event/${eventId}`),
};

export default userServices;
