import { axios, axios2 } from '../utils/axios';
import { CountryType, CategoryType, PlatformType, CreatorType } from '@/stores/CommonStore/types';

export interface ICountriesResponse {
  list: CountryType[];
}

export interface ICategoriesResponse {
  list: CategoryType[];
}
export interface IPlatformsResponse {
  list: PlatformType[];
}

export interface ICreatorsResponse {
  list: CreatorType[];
}

const commonServices = {
  getCountries: () => axios2.get<ICountriesResponse, ICountriesResponse>('studio/v1/countries'),
  getCategories: () => axios2.get<ICategoriesResponse, ICategoriesResponse>('studio/v1/categories'),
  getPlatforms: () => axios2.get<IPlatformsResponse, IPlatformsResponse>('studio/v1/platforms'),
  getCreators: () =>
    axios2.get<ICreatorsResponse, ICreatorsResponse>('studio/v1/creators', {
      params: { pageSize: 320 },
    }),
  getOssPolicy: () => axios.get('/interface/v1/apply/policy'),
};

export default commonServices;
