import UserStore from './UserStore';
import CommonStore from './CommonStore';
import LanguageStore from './LanguageStore';
import EarningStore from './EarningStore';

const stores = {
  user: new UserStore(),
  common: new CommonStore(),
  language: new LanguageStore(),
  earning: new EarningStore(),
};

export default stores;
