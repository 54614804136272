// console.log('window.location.protocol', window.location.protocol);

const handleBaseUrl = (local?: string, protocol?: string) => {
  let baseUrl = '';
  // let dataUrl = '';

  switch (local) {
    case 'www.adobaro.com':
    case 'adobaro.com':
    case 'v1.adobaro.com':
    case '3.38.221.35': // 临时 生产环境
      // baseUrl = `${protocol}//api.adobaro.com`;
      baseUrl = `https://api.adobaro.com`;
      // dataUrl = `http://3.38.221.35:9080`;
      break;
    default:
      baseUrl = 'https://api.dev.adobaro.com';
      // dataUrl = `http://18.163.16.71:9080`;
      break;
  }

  return { baseUrl /* dataUrl */ };
};

const CONFIG = {
  BASEURL: handleBaseUrl(window.location.host, window.location.protocol).baseUrl,
  // DATAURL: handleBaseUrl(window.location.host, window.location.protocol).dataUrl,
};

export default CONFIG;
