import React, { Suspense } from 'react';
import { Provider } from 'mobx-react';
import { useRoutes } from 'react-router-dom';
import stores from './stores';
import routes from './routers';
import Loading from '@/components/Loading/loading';
import './i18n/config'; // 引用配置文件
import './App.less';

const App: React.FC = () => {
  const routing = useRoutes(routes);
  return (
    <Provider {...stores}>
      <Suspense fallback={<Loading />}>{routing}</Suspense>
    </Provider>
  );
};

export default App;
