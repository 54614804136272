import React from 'react';
// import Lottie from 'lottie-react';
import { Image } from 'antd';
// import loadingAnimation from '@/assets/lottie/meta_loading.json';
import AdobaLoading from '@/assets/layout/adobaLoading.gif';
import { useWindowSize } from '@/utils/hooks/useWindowSize';
import styles from './index.module.less';

// 切换页面时的加载动画
const LoadingScreen: React.FC = () => {
  const { windowHeight } = useWindowSize();
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: loadingAnimation,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice',
  //   },
  // };

  return (
    <div className={styles.box} style={{ height: windowHeight }}>
      <div className={styles.content}>
        {/* <Lottie {...defaultOptions} className={styles.lottie} /> */}
        <Image width={150} src={AdobaLoading} preview={false} />
        {/* <p className={styles.tip}>Data Loading...</p> */}
      </div>
    </div>
  );
};

export default LoadingScreen;
