import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const DashboardLayout = lazy(() => import('@/layouts/DashboardLayout'));
const MainLayout = lazy(() => import('@/layouts/MainLayout'));

const Home = lazy(() => import('@/pages/Home'));
const Protocols = lazy(() => import('@/pages/Protocols'));
const ProtocolDetail = lazy(() => import('@/pages/Protocols/ProtocolDetail'));
// 开通平台
const OpenPlatform = lazy(() => import('@/pages/OpenPlatform'));
const PlatformMaterial = lazy(() => import('@/pages/OpenPlatform/PlatformMaterials'));
// 翻译服务
const TranslationService = lazy(() => import('@/pages/TranslationService'));
const SRTTranslation = lazy(() => import('@/pages/TranslationService/SRTTranslation'));
const AITranslation = lazy(() => import('@/pages/TranslationService/AITranslation'));
const ManualTranslation = lazy(() => import('@/pages/TranslationService/ManualTranslation'));
const TranslationDownload = lazy(() => import('@/pages/TranslationService/TranslationDownload'));

// 运营服务
// const OperatingServices = lazy(() => import('@/pages/OperatingServices'));
// 平台新闻
// const PlatformNews = lazy(() => import('@/pages/PlatformNews'));
// 下载
const Download = lazy(() => import('@/pages/Download'));
// 活动
const Activity = lazy(() => import('@/pages/Activity'));
// 个人中心
const UserCenter = lazy(() => import('@/pages/UserCenter'));
const OpenSchedule = lazy(() => import('@/pages/UserCenter/OpenSchedule'));
const OperationalData = lazy(() => import('@/pages/UserCenter/OperationalData'));
const PaymentHistory = lazy(() => import('@/pages/UserCenter/PaymentHistory'));
const Token = lazy(() => import('@/pages/UserCenter/Token'));
const IdentityInfo = lazy(() => import('@/pages/UserCenter/IdentityInfo'));
const IncomeSituation = lazy(() => import('@/pages/UserCenter/IncomeSituation'));
const BankInfo = lazy(() => import('@/pages/UserCenter/IncomeSituation/BankInfo'));
const PlatformIncomes = lazy(() => import('@/pages/UserCenter/IncomeSituation/PlatformIncomes'));
const PassportInfo = lazy(() => import('@/pages/UserCenter/IdentityInfo/PassportInfo'));
const OverseaInfo = lazy(() => import('@/pages/UserCenter/IdentityInfo/OverseaInfo'));
const ModifyInformation = lazy(() => import('@/pages/UserCenter/ModifyInformation'));

const NotFound = lazy(() => import('@/pages/NotFound'));
const Login = lazy(() => import('@/pages/Login'));

const routes = [
  {
    path: '',
    element: <DashboardLayout />,
    children: [
      // { path: '/app', element: <Navigate to="/open-platform" /> },
      { path: '', element: <Home /> },
      { path: 'protocols', element: <Protocols /> },
      { path: 'protocol-detail', element: <ProtocolDetail /> },
      { path: 'open-platform', element: <OpenPlatform /> },
      { path: 'platform-material', element: <PlatformMaterial /> },

      {
        path: 'translation-service',
        element: <TranslationService />,
        children: [
          { path: 'srt-translation', element: <SRTTranslation /> },
          { path: 'ai-translation', element: <AITranslation /> },
          { path: 'manual-translation', element: <ManualTranslation /> },
          { path: 'translation-download', element: <TranslationDownload /> },
        ],
      },
      // { path: 'operating-services', element: <OperatingServices /> },
      // { path: 'platform-news', element: <PlatformNews /> },
      { path: 'download', element: <Download /> },
      { path: 'activity', element: <Activity /> },

      {
        path: 'user-center',
        element: <UserCenter />,
        children: [
          { path: 'open-schedule', element: <OpenSchedule /> },
          { path: 'operational-data', element: <OperationalData /> },
          { path: 'payment-history', element: <PaymentHistory /> },
          { path: 'token', element: <Token /> },
          { path: 'identity-info', element: <IdentityInfo /> },
          { path: 'identity-info/passport-info', element: <PassportInfo /> },
          { path: 'identity-info/oversea-info', element: <OverseaInfo /> },
          { path: 'income-situation', element: <IncomeSituation /> },
          { path: 'income-situation/bank-info', element: <BankInfo /> },
          { path: 'income-situation/platform-incomes', element: <PlatformIncomes /> },
          { path: 'modify-information', element: <ModifyInformation /> },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFound /> },
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to="/" /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
  // {
  //   path: '',
  //   element: <MainLayout />,
  //   children: [
  //     { path: 'login', element: <Login /> },
  //     { path: '', element: <Navigate to="/" /> },
  //   ],
  // },
];

export default routes;
